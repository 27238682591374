/* eslint-disable */

//External packages
import { RestApiService } from "./RestApiService";
import { getProjectToken } from '../utils/helpers/TokenHelper';
import '../utils/helpers/AxiosInterceptor';
import axios from "axios";

//Internal packages
import { Host_Stratigraphic } from "../Config/Host";

export async function uploadWellMarkers(params){
  var endpoint = `${Host_Stratigraphic}/stratigraphic/markers/upload`;
    return new Promise((resolve, reject) => {
    var token = getProjectToken();
    RestApiService.upload(endpoint, params, token)
    .then(response => {      
      resolve({response});
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function getMarkers() {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Stratigraphic}/stratigraphic/thickness`;
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function getMarkersData() {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Stratigraphic}/stratigraphic/`;
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function addNewMarkersData(markers_data) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Stratigraphic}/stratigraphic/markers/add`;
    RestApiService.post(endpoint, markers_data, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function downloadMarkers(params){
  var endpoint = `${Host_Stratigraphic}/stratigraphic/markers/download`;
  var token = getProjectToken();
  await axios.post(endpoint, params, {
    headers: {
      'NautilusAuthorization': `NautilusToken ${token}`,
      'MachleeAuthorization': `MachleeToken ${token}`,
    }
  })
  .then((response) => {
    
    const fileName = Object(params)["filename"];
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE variant
      window.navigator.msSaveOrOpenBlob(
        new Blob([response.data], {
          type: 'text/plain',
          encoding: 'UTF-8'
        }),
        fileName
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'text/plain',
          encoding: 'UTF-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
  
}