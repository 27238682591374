import React, { Component } from 'react';

interface TableViewTabComponentState {
  
}

class TableViewTabComponent extends Component<{}, TableViewTabComponentState> {
  constructor(props: any) {
    super(props);
  }  

  render() {

    return (
      <>
        <p>Table</p>
      </>
    );
  }
}

export default TableViewTabComponent;
