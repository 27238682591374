import React, { Component } from 'react';
import { ClickableLabel } from "../ClickableLabel/ClickableLabel";

interface NodeComponentState {
  
}

export class NodeComponent {
    
    value: string;
    label: any;
    children: any[] | undefined
    callback: (callbackArgs: any) => void;
    callbackArgs: any
  
    constructor(
        value: string,
        label: any,
        callback: (callbackArgs: any) => void,
        callbackArgs: any,
        children: any[] | undefined) {
            this.value = value;            
            this.callback = callback;
            this.callbackArgs = callbackArgs;
            this.label = this.getLabelComponent(label, callback, callbackArgs);
            this.children = this.getChildren(children);
        }

    getLabelComponent(childLabel: string, childCallback: any, childCallbackArgs: any){        
        return <ClickableLabel text={childLabel} callback={childCallback} childCallbackArgs={childCallbackArgs}/>;
    }

    getChildren(children: any){
        if (!children)
            return [];

        var arr:any = [];
        for(var i = 0; i<children.length; i++){
            var child = children[i]
            arr.push({
                value: child.value,
                label: this.getLabelComponent(child.label, child.callback, child.callbackArgs),
                children: child.getChildren()
            });
        }
        return arr;
    }   
}

