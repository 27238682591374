import React, { Component } from 'react';
import { wellMetadata } from '../../services/wellService';
import WellMetadataContainer from '../../utils/models/WellMetatada/WellMetadataContainer';
import GlobalWellMetadataInfo from '../../global/GlobalWellMetadataInfo';
import SingletonCheckboxTreeComponent from './SingletonCheckboxTreeComponent';
import WellMetadata from '../../utils/models/WellMetatada/WellMetadata';
import { createTreeViewRepresentation } from './TreeViewRepresentation';
import WellNode from './WellNode';
import { EventNames } from '../../utils/enums/EventNames';
import { refreshWellsNodes } from './SingletonCheckboxTreeComponent';
// import InpetuSerie from '../../utils/models/Inpetu/InpetuSerie';
// import InpetuModel from '../../utils/models/Inpetu/InpetuModel';

interface TreeViewWellComponentState {
  wellNodes: any;
}

/**
 * Event to refresh Wells tree in sidebar.
 */
export function refreshWellsTree(){
  const customEvent = new CustomEvent(EventNames.refreshWellsTreeViewEvent, { detail: {} });
  document.dispatchEvent(customEvent);
}

export default class TreeViewWellComponent extends Component<{}, TreeViewWellComponentState> {
  wellMetadata: GlobalWellMetadataInfo;
  refreshWellTreeEvent: string = EventNames.refreshWellsTreeViewEvent;

  componentDidMount() {  
    document.addEventListener(this.refreshWellTreeEvent, this.refreshWellView);
}

componentWillUnmount() {
    document.removeEventListener(this.refreshWellTreeEvent, this.refreshWellView);
}

refreshWellView =(event:any) =>{
  if (event.type !== this.refreshWellTreeEvent){
    return;
  }
  this.loadWellsData();  
}

  getWellNodeFormat = (wellMetadata: WellMetadata) => {
    var wellInfoFilteredList = wellMetadata.wellInfo.filter((wellInfoItem: any)=>{ return wellInfoItem.name == 'WELL'})
    //var wellName = wellInfoFilteredList[0].value;
    var version = wellMetadata.versionMetadata[0];
    let curveNames:string[] = [];

    if(version){
      curveNames = version.curveMetatdataInfoList.map((item) => item.name);
    }

    return {
      titulo: wellMetadata.name,
      curves: [
        {
          name: "versions",
          type: "Continuous",
          logs: curveNames,
        },
      ],
      deviation: wellMetadata.deviationsLoaded,
      versionId: version? version.id : null,
      wellId: wellMetadata.id ,
    }
  }

  constructor(props: any) {
    super(props);
    this.loadWellsData();
    this.state = {
      wellNodes: [],
    }
  }

  loadWellsData = ()=>{
    wellMetadata().then((result)=>{
      var wellMetadata = new WellMetadataContainer(result);
      GlobalWellMetadataInfo.createNewInstance(wellMetadata);
      this.wellMetadata = GlobalWellMetadataInfo.getInstance(wellMetadata);
      var wellMetadataList = this.wellMetadata.getData().wellMetadataList; 
      var wellNodeFormatedList = [];
      for( var i=0; i<wellMetadataList.length;i++){
        var wellNodeFormated = this.getWellNodeFormat(wellMetadataList[i]);
        wellNodeFormatedList.push(wellNodeFormated);
      }

      var treeViewRepresentation = createTreeViewRepresentation(wellNodeFormatedList, true);
      var wellNodes = WellNode.getNodes(treeViewRepresentation.wells);      
      this.setState({wellNodes:wellNodes});      
      refreshWellsNodes(wellNodes)
    }).catch((error) => {
      console.error(error);
    });
  }
  
  render() {
    // if (!this.state) {
    //   return <></>; 
    // }      
  
    // if (!wellNodes) {
    //   return <></>; 
    // }
    
    var containerFluidStyle = {
      width: '100%',
      paddingLeft: '0',
      paddingRight: '0',
    };

    if(!(this.state.wellNodes.length > 0)){
      return ( 
        <>
          <div className='sidebar-section' style={{'paddingBottom': '5px'}}>                                      
            <details>
            <summary className='title'>Wells <i className="arrow down"></i></summary>
                <div>
                  <ul>
                    <li>No wells yet</li>
                  </ul>
                </div>
            </details>                       
          </div> 
        </>
      )
    }

    return (
      <>
        <div className='sidebar-section' style={{'paddingBottom': '5px'}}>                                      
            <details>
            <summary className='title'>Wells <i className="arrow down"></i></summary>
                <div>
                  <SingletonCheckboxTreeComponent nodes={this.state.wellNodes}/>
                </div>
            </details>                       
        </div>                     
      </>                 
    );
  }  
}
