import React, {Component} from 'react';
import { FaTrash, FaBrush } from "react-icons/fa";
import {EventNames} from "../../utils/enums/EventNames";

interface LabelWellProps {
    callback: () => void;
    children: any;
}


export default class LabelWell extends Component<LabelWellProps,{}>{
    handleTrashClick = (event: any) => {
        event.preventDefault();
        this.props.callback();
    }
    handleBrushClick = (event: any) =>{
        event.preventDefault();
        const customEvent = new CustomEvent(EventNames.wellColorsConfigEvent, { detail: {}});
        document.dispatchEvent(customEvent);
    }

    render(){
        return <div style={{ display: 'inline' }}>  
                    <span style={{ fontSize: 14 }}>
                    {this.props.children} 
                    <FaTrash style={{ width: '10px', marginLeft:'6px', position: 'relative', top:'-1px' }} onClick={this.handleTrashClick}/>
                    <FaBrush style={{ width: '10px', marginLeft:'6px', position: 'relative', top:'-1px' }} onClick={this.handleBrushClick}/>
                    </span>  
                </div>
    }
}