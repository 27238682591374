import React, { Component } from 'react';

interface MapViewTabComponentState {

}

class MapViewTabComponent extends Component<{}, MapViewTabComponentState> {
  constructor(props: any) {
    super(props);
    
  }

  render() {  
    return (
      <> 
      Map 
      </>
    );
  }
}

export default MapViewTabComponent;
