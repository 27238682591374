export default class StringHelper {
    
    static replaceSpacesWithHyphens = (inputString: string): string => {
        return '-' + inputString.replace(/ /g, '-');
    }

    static escapeRegexCharacters = (str: any) => {
        return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    
    static replaceSubstringWithQuote = (inputString: any, substringToReplace: any) => {
        const escapedSubstring = StringHelper.escapeRegexCharacters(substringToReplace);
        const regex = new RegExp(escapedSubstring, 'g');
        return inputString.replace(regex, '"');
    }

    static removeNotAllowedCharacters = (str: string) => {
        //remove '\n'
        return str.replace(/\n/g, '');
      }

    static removeExtension = (fileName: string) => {
        const extensionIndex = fileName.lastIndexOf('.');
      
        if (extensionIndex !== -1 && extensionIndex !== 0) {
          return fileName.slice(0, extensionIndex);
        }
      
        return fileName;
      }

    static parseIdStringToObject = (inputString: string) => {
      const jsonStringArray = inputString.split(';').filter(Boolean); // Split the string by ';' and remove empty strings
    
      const resultObject = jsonStringArray.reduce((accumulator: any, currentValue: any) => {
        const parsedJson = JSON.parse(currentValue);
        const key = Object.keys(parsedJson)[0]; // Extract the key from the parsed JSON
        accumulator[key] = parsedJson[key]; // Assign the parsed value to the key in the result object
        return accumulator;
      }, {});
    
      return resultObject;
    }
}

