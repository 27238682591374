import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getToken } from '../../utils/helpers/TokenHelper';
import HomePage from '../../pages/Home/HomePage';
import LoginPage from '../../pages/Login/LoginPage';
import SignUpPage from '../../pages/Login/SignUpPage';

import UserProjects from '../../pages/UserProjects/UserProjects';

class RouteComponent extends Component {
  isAuthenticated = () => {
    var token = getToken();
    if(token){
      return token;
    } else {
      return null;
    }
  } 

  getRouter = () => {
    if(!this.isAuthenticated()){
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/signup" element={<SignUpPage />} />  
            <Route path="/*" element={<LoginPage />} />                  
          </Routes>
        </BrowserRouter>
      )
    } else {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/projects" element={<UserProjects />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />        
          </Routes>
        </BrowserRouter>
      )
    }
  }

  render() {
    return (
      this.getRouter()
    );
  }

}

export default RouteComponent;
