/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ToastHelper from "../../utils/helpers/ToastHelper";
import * as Yup from "yup";
import { Button, Card, CardBody, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { registerUser } from "../../services/userService";

interface SignUpState {
  first_name: any;
  last_name: any;
  email: any;
  username: any;
  password: any;
  touchedFirst_name: any;
  errorsFirst_name: any;
  errorsLast_name: any;
  touchedLast_name: any;
  errorsEmail: any;
  touchedEmail: any;
  errorsUsername: any;
  touchedUsername: any;
  touchedPassword: any;
  errorsPassword: any;
}

class SignUp extends Component<{}, SignUpState> {

  usernameSchema: any;
  passwordSchema: any;  
  firstnameSchema: any;
  lastnameSchema: any;  
  emailSchema: any;

  constructor(props: any) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      touchedFirst_name: false,      
      touchedLast_name: false,      
      touchedEmail: false,
      touchedUsername: false,
      touchedPassword: false, 
      errorsUsername: false,
      errorsPassword: false,
      errorsEmail: false,
      errorsFirst_name: false,
      errorsLast_name: false,           
    }
    
    this.firstnameSchema = Yup.object().shape({
      first_name: Yup.string()
        .min(2, "Mininum 2 characters")
        .required("Required!")
    });

    this.lastnameSchema = Yup.object().shape({
      last_name: Yup.string()
        .min(1, "Mininum 2 characters")
        .required("Required!")
    });

    this.emailSchema = Yup.object().shape({
      email: Yup.string()
        .email()
        .min(2, "Mininum 3 characters")
        .required("Required!")
    });

    this.usernameSchema = Yup.object().shape({
      username: Yup.string()
        .min(2, "Mininum 2 characters")
        .required("Required!")
    });
    
    this.passwordSchema = Yup.object().shape({
      password: Yup.string()
        .min(3, "Minimum 3 characters")
        .required("Required!"),
    });  
  }  

  handleChangeFirstName = (event: any) => {
    var touchedFirst_name = true;
    if(event.target.value == ''){
      touchedFirst_name = false;
    }
    this.setState( {
      first_name: event.target.value,
      touchedFirst_name: touchedFirst_name
    });
  }

  handleChangeLastName = (event: any) => {
    var touchedLast_name = true;
    if(event.target.value == ''){
      touchedLast_name = false;
    }
    this.setState( {
      last_name: event.target.value,
      touchedLast_name: touchedLast_name
    });
  }

  handleChangeEmail = (event: any) => {
    var touchedEmail = true;
    if(event.target.value == ''){
      touchedEmail = false;
    }
    this.setState( {
      email: event.target.value,
      touchedEmail: touchedEmail
    });
  }

  handleChangeUserName = (event: any) => {
    var touchedUsername = true;
    if(event.target.value == ''){
      touchedUsername = false;
    }
    this.setState( {
      username: event.target.value,
      touchedUsername: touchedUsername
    });
  }

  handleChangePassword = (event: any) => {
    var touchedPassword = true;
    if(event.target.value == ''){
      touchedPassword = false;
    }
    this.setState( {
      password: event.target.value,
      touchedPassword: touchedPassword
    });
  }

  isValidEmail = () => {
    return new Promise((resolve, reject) => {
      this.emailSchema.validate({email: this.state.email}).then((result: any) => {
        resolve(result);
      }).catch((error: any) => {
        this.setState( {
          errorsEmail: error.errors
        });
      });      
    });     
  }

  isValidFirst_name = () => {
    return new Promise((resolve, reject) => {
      this.firstnameSchema.validate({first_name: this.state.first_name}).then((result: any) => {
        resolve(result);
      }).catch((error: any) => {
        this.setState( {
          errorsFirst_name: error.errors
        });
      });      
    });     
  }

  isValidLast_name = () => {
    return new Promise((resolve, reject) => {
      this.lastnameSchema.validate({last_name: this.state.last_name}).then((result: any) => {
        resolve(result);
      }).catch((error: any) => {
        this.setState( {
          errorsLast_name: error.errors
        });
      });      
    });     
  }

  isValidUsername = () => {
    return new Promise((resolve, reject) => {
      this.usernameSchema.validate({username: this.state.username}).then((result: any) => {
        resolve(result);
      }).catch((error: any) => {
        this.setState( {
          errorsUsername: error.errors
        });
      });      
    });     
  }

  isValidPassword = () => {
    return new Promise((resolve, reject) => {
      this.passwordSchema.validate({password: this.state.password}).then((result: any) => {
        resolve(result);
      }).catch((error: any) => {
        this.setState( {
          errorsPassword: error.errors
        });
      });      
    });     
  }

  async create() {    
    registerUser({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      username: this.state.username,
      password: this.state.password
    })
    .then((result) => {
      if(result.error){   
        ToastHelper.warning(result.message).then(message => console.log(message));
        return;
      }
      window.location.href = '/projects';
    })
    .catch((result) => {
      ToastHelper.error("Server Error", result).then(message => {
        console.log(message)
      }
      );
    });
  }

  validateAndCreate = () => {    
    this.isValidFirst_name().then(() => {
      this.isValidLast_name().then(() => {
        this.isValidEmail().then(() => {
          this.isValidUsername().then(() => {
            this.isValidPassword().then(() => {
              this.create();        
            });          
          });         
        });       
      });
    });   
  }

  handleSubmit = (event: any) => {
    this.setState( {
      errorsUsername: false,
      errorsPassword: false,
      errorsEmail: false,
      errorsFirst_name: false,
      errorsLast_name: false,
    }, () => {
      this.validateAndCreate();
    });     
  }

  render() {
    return (
      <>
        <Card style={{ marginTop: "37vh" }}>
          <Row>
            <Col size={12}></Col>
          </Row>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center"></div>
              <Form>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormGroup style={{ width:'55vh' }}>
                  <Label style={{ marginBottom: "10px" }}>First Name</Label>
                  <Input style={{ marginBottom: "20px" }}
                    bsSize="lg"
                    type="text"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.handleChangeFirstName}
                    placeholder="First Name"
                  />
                  {this.state.errorsFirst_name && (
                    <p className="text-danger">{this.state.errorsFirst_name}</p>
                  )}
                </FormGroup>
                <FormGroup style={{ marginLeft: "50px", width:'55vh' }}>
                  <Label style={{ marginBottom: "10px" }}>Last Name</Label>
                  <Input style={{ marginBottom: "20px" }}
                    bsSize="lg"
                    type="text"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleChangeLastName}
                    placeholder="Last Name"
                  />
                  {this.state.errorsLast_name && (
                    <p className="text-danger">{this.state.errorsLast_name}</p>
                  )}
                </FormGroup>
                </div>
                <FormGroup>
                  <Label style={{ marginBottom: "10px" }}>Email</Label>
                  <Input style={{ marginBottom: "20px" }}
                    bsSize="lg"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    placeholder="Email"
                  />
                  {this.state.errorsEmail && (
                    <p className="text-danger">{this.state.errorsEmail}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label style={{ marginBottom: "10px" }}>User Name</Label>
                  <Input style={{ marginBottom: "20px" }}
                    bsSize="lg"
                    type="text"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChangeUserName}
                    placeholder="User Name"
                  />
                  {this.state.errorsUsername && (
                    <p className="text-danger">{this.state.errorsUsername}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label style={{ marginBottom: "10px" }}>Password</Label>
                  <Input style={{ marginBottom: "20px" }}
                    bsSize="lg"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    placeholder="Password"
                  />
                  {this.state.password && (
                    <p className="text-danger">{this.state.errorsPassword}</p>
                  )}
                  <small>
                    <Link to="#">Forgot password?</Link>
                  </small>
                </FormGroup>
                <div className="text-center mt-3">                  
                  <Button 
                    color="primary" 
                    size="lg"
                    onClick={this.handleSubmit}  
                  >
                    Sign up
                  </Button>                  
                  <br />
                  <br />
                  <Link to="/login">Sign-In</Link>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default SignUp;