import WellMetadata from "./WellMetadata";


export default class WellMetadataContainer {
    wellMetadataList: WellMetadata[];  

    constructor(data: any) {
        this.wellMetadataList = [];
        var keys = Object.keys(data);
        for(var i=0;i<keys.length;i++){
            var key = keys[i];
            var value = data[key];
            if(key == 'msg'){
                continue;
            }
            var wellMetadata = new WellMetadata(value);
            wellMetadata.name = key
            this.wellMetadataList.push(wellMetadata);
        }             
    }
}