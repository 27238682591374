//External packages
import React, { Component } from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Internal packages
import { Timer } from './ProgressBarTimer';

interface ProgressBarState {
    progress:number,
    message:string
}

interface ProgressBarProps {
    defaultValue:number,
    context:string,
    title:string
}

const SETVALUE = "setValue"

export function setProgress(value:number, context:string, message:string){
    const customEvent = new CustomEvent(SETVALUE, { detail: {
        value:value,
        context:context,
        message:message
    } 
    });
    document.dispatchEvent(customEvent);
}

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} size={200} color="inherit" />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize={70}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

export class ProgressBar extends Component<ProgressBarProps, ProgressBarState> {
    constructor(props:ProgressBarProps){
        super(props);
        this.state = {
            progress:this.props.defaultValue,
            message:"",
        }
    }

    componentDidMount() {  
        document.addEventListener(SETVALUE, this.setValueEvent);
      }
    
      componentWillUnmount() {
        document.removeEventListener(SETVALUE, this.setValueEvent);
      }

    setValueEvent = (event:any) => {
        if (event.type !== SETVALUE){
          return;
        }
        let dataset = event.detail;
        if( dataset.context == this.props.context ){
            this.setState({
                progress:dataset.value,
                message:dataset.message
            });
        }
      }

    render(): React.ReactNode {
        return(
            <>
                <div className='row'>
                    <div className='col' style={{textAlign:"center"}}>
                        <h1>{this.props.title}</h1>
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col d-flex justify-content-center'>
                        <CircularProgressWithLabel value={this.state.progress} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col' style={{textAlign:"center"}}>
                        <h2>{this.state.message}</h2>
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    Running time: <Timer />
                </div>
            </>
        )
    }
}