import React, { Component } from 'react';
import { saveUserConfs } from '../../../services/projectService';
import { wellMetadata } from '../../../services/wellService';
import { EventNames } from "../../../utils/enums/EventNames";
// import PersonalizeWellTrackColors from "../../ConfirmDialog/PersonalizeWellTrackColors";
import { getUserConfs } from '../../../services/projectService';
import ReactModal from 'react-modal';

interface SettingsTabComponentState {
  wellViewState:WellViewStates,
  wellTracksColorTemporary:any,
  wellTracksColor:any,
  showModal:boolean,
  logNames:any[],
  timerToSend:any,
}
interface WellViewStates{
  trackWidth:any,
  trackLinesThickness:any,
  trackgap:any,
  trackborderColor:any,
  trackheadersborderColor:any,
  trackFontSize:any,
  trackFontName:any,
  trackShowZoomBar:boolean,
  trackShowVerticalScale:boolean,
  trackHeight:number,
  correlationDepth:string
}

const well_depth = [
  {key:"RGT", show:"RGT"},
  {key:"MD", show:"MD"},
  {key:"TVD", show:"TVD"},
  {key:"TVDSS", show:"TVDSS"}
];

const depthsNames = [
  well_depth[0].key,
  well_depth[1].key,
  well_depth[2].key,
  well_depth[3].key
]

const refreshConfig = "RefreshConfigSidebar"

/**
 * Event to change Correlation data in Correlation View Tab.
 */
export function updateConfig(){
  const customEvent = new CustomEvent(refreshConfig, { detail: {} });
  document.dispatchEvent(customEvent);
}

class SettingsTabComponent extends Component<{}, SettingsTabComponentState> {

  updateSettingsEvent: string = EventNames.refreshWellTrackPlotEvent;
  changeCurveColorsEvent: string = EventNames.wellColorsConfigEvent;

  constructor(props: any) {
    super(props);
    this.state = {
      showModal:false,
      logNames:[],
      wellTracksColorTemporary:{
      },
      wellTracksColor:{},
      wellViewState:{
        trackWidth:200,
        trackLinesThickness:1,
        trackgap:10,
        trackborderColor:'#EEEFF0',
        trackheadersborderColor:'#EEEFF0',
        trackFontSize:11,
        trackFontName:"Arial",
        trackShowZoomBar:true,
        trackShowVerticalScale:true,
        trackHeight:700,
        correlationDepth:well_depth[0].key,
      },
      timerToSend:null
    }
    this.refreshConfig()
  }

  refreshConfig = () =>{
    getUserConfs().then((userConfs)=>{
      if(!userConfs.plotView){
        userConfs = {
          plotView:{},
          userConfs:{}
        }
      }
      // Cleanup this code
      if(userConfs.plotView.plotViewConf){
        userConfs.plotView.plotViewConf.trackShowZoomBar = userConfs.plotView.plotViewConf.trackShowZoomBar=="true" ? true : false
        userConfs.plotView.plotViewConf.trackShowVerticalScale = userConfs.plotView.plotViewConf.trackShowVerticalScale=="true" ? true : false        
      }

      if(!userConfs.plotView.plotViewConf){
        userConfs.plotView.plotViewConf = {
          trackWidth:200,
          trackLinesThickness:2,
          trackgap:10,
          trackborderColor:'#EEEFF0',
          trackheadersborderColor:'#EEEFF0',
          trackFontSize:11,
          trackFontName:"Arial",
          trackShowZoomBar:true,
          trackShowVerticalScale:true,
          trackHeight:700,
          correlationDepth: "RGT"
        }
      }
      this.setState({
        wellTracksColor: userConfs.wellTracksColor?userConfs.wellTracksColor:{},
        wellTracksColorTemporary: userConfs.wellTracksColor?userConfs.wellTracksColor:{},
        wellViewState: userConfs.plotView.plotViewConf,
      })
    })
  }

  componentDidMount() {
    document.addEventListener(this.changeCurveColorsEvent, this.colorConfig);
    document.addEventListener(refreshConfig, this.refresh);
    this.createInitialColors();
  }

  componentWillUnmount() {
    document.removeEventListener(this.changeCurveColorsEvent, this.colorConfig);
    document.removeEventListener(refreshConfig, this.refresh);
  }

  createColor = () => {
    // The available hex options
    let hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    /**
     * Randomly shuffle an array
     * https://stackoverflow.com/a/2450976/1293256
     */
    let shuffle = (hex:any) => {

        let currentIndex = hex.length;
        let temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = hex[currentIndex];
            hex[currentIndex] = hex[randomIndex];
            hex[randomIndex] = temporaryValue;
        }

    };

    /**
     * Create a six-digit hex color
     */
    let hexColor = () => {
        // Create the color
        let color = '#';

        // Shuffle the hex values and append
        for (let i = 0; i < 6; i++) {
            shuffle(hex);
            color += hex[0];
        }

        return color;
    };

    // Return the color string
    return hexColor();
  }

  fillCurveNames(){
    wellMetadata().then((response)=>{
      const curveColorsTemporary: {[index: string]:any} = this.state.wellTracksColorTemporary;
      const curveColors: {[index: string]:any} = this.state.wellTracksColor;
      Object.keys(response).forEach((wellName:any, idx)=>{
        Object.keys(response[wellName].curves.versions[0].curves_info).forEach((logName:any,logIdx)=>{
          if(!curveColors[logName]){
            /** Set colors here! */
            const color = this.createColor();
            curveColors[logName] = {line: color, fill: color}
            curveColorsTemporary[logName] = {line: color, fill: color}
          }
        })
      })
      this.setState({logNames:Object.keys(curveColors),wellTracksColorTemporary:curveColorsTemporary, wellTracksColor:curveColors},()=>{
        this.setState({showModal:true},()=>{
          this.render()          
        })
      })
    })
  }

  createInitialColors = () => {
    wellMetadata().then((response)=>{
      const curveColorsTemporary: {[index: string]:any} = this.state.wellTracksColorTemporary;
      const curveColors: {[index: string]:any} = this.state.wellTracksColor;
      Object.keys(response).forEach((wellName:any, idx)=>{
        if(response[wellName].curves){
          Object.keys(response[wellName].curves.versions[0].curves_info).forEach((logName:any,logIdx)=>{
            if(!curveColors[logName]){
              /** Set colors here! */
              const color = this.createColor();
              curveColors[logName] = {line: color, fill: color}
              curveColorsTemporary[logName] = {line: color, fill: color}
            }
          })
        }
      })
      this.setState({logNames:Object.keys(curveColors),wellTracksColorTemporary:curveColorsTemporary, wellTracksColor:curveColors},()=>{
        this.saveConf();
      })
    })
  }

  colorConfig = (event:any) =>{
    if (event.type !== this.changeCurveColorsEvent){
      return;
    }
    this.fillCurveNames();
  }

  refresh = (event:any) =>{
    if (event.type !== refreshConfig){
      return;
    }
    this.createInitialColors();
  }

  setColors = (save:boolean) =>{
    if(save){
      this.setState({wellTracksColor:this.state.wellTracksColorTemporary},()=>{
        // var show = this.state.wellTracksColor;
        this.saveConf();
      });
    }
    else {
      var prevState = this.state.wellTracksColor;
      this.setState({wellTracksColorTemporary:JSON.parse(JSON.stringify(prevState))},()=>{
        this.render();
      });
    }
  }

  setTrackTemporaryColor = (logName:any, colorType:any, colorValue:String) => {
    let prevState = this.state.wellTracksColorTemporary;
    prevState[logName][colorType] = colorValue;
    this.setState({wellTracksColorTemporary:prevState},()=>{this.render()})
  }

  waitToSaveConfs = () => {
    if(this.state.timerToSend != null){
      clearTimeout(this.state.timerToSend);
    }
    var timer = setTimeout(this.saveConf,1000);
    this.setState({timerToSend:timer});
  }

  /**
   * Perform save configs
   */
  saveConf = () => {
    // Send event to update view
    const customEvent = new CustomEvent(this.updateSettingsEvent, { detail: { settings:this.state } 
    });
    document.dispatchEvent(customEvent);
    var dataset:any[] = []
    // Iterate in well view states
    Object.entries(this.state.wellViewState).forEach((value,idx) => {
      dataset.push({"topic":"plotView","group":"plotViewConf","key":value[0], "value":value[1].toString()});
    });
    Object.entries(this.state.wellTracksColor).forEach((value:any,idx) => {
      Object.entries(value[1]).forEach((fillType,fillTypeIdx)=>{
        dataset.push({"topic":"wellTracksColor","group":value[0],"key":fillType[0], "value":fillType[1]});
      })
    });
    // Save new conf
    saveUserConfs(dataset).then((response)=>{
      this.setState({timerToSend:null});
    }).catch((error)=>{
      this.setState({timerToSend:null});
    });
  }

  setTrackWidth = (event:any) => {
    var state = this.state.wellViewState;
    state.trackWidth = parseInt(event.target.value)
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTrackLinesThickness = (event:any) => {
    var state = this.state.wellViewState;
    state.trackLinesThickness = parseInt(event.target.value)
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTrackGap = (event:any) => {
    var state = this.state.wellViewState;
    state.trackgap = parseInt(event.target.value)
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksBorderColor = (event:any) => {
    var state = this.state.wellViewState;
    state.trackborderColor = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksHeaderBorderColor = (event:any) => {
    var state = this.state.wellViewState;
    state.trackheadersborderColor = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksfontsType = (event:any) => {
    var state = this.state.wellViewState;
    state.trackFontName = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setCorrelationDepthType = (event:any) => {
    var state = this.state.wellViewState;
    state.correlationDepth = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksfontsSize = (event:any) => {
    var state = this.state.wellViewState;
    state.trackFontSize = parseInt(event.target.value);
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setShowVerticalScale = (event:any) => {
    var state = this.state.wellViewState;
    state.trackShowVerticalScale = event.target.checked;
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setShowTracksZoom = (event:any) => {
    var state = this.state.wellViewState;
    state.trackShowZoomBar = event.target.checked;
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksHeight = (event:any) => {
    var state = this.state.wellViewState;
    state.trackHeight = parseInt(event.target.value);
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }

  getFontsTypeView = () => {
    var fontNames = ["Arial","other"]
    return <>
      <select name="fontType" onChange={(event)=>{this.setTracksfontsType(event)}}>
        {fontNames.map((value:string,idx:any)=>{
          var checked = false
          if(value == this.state.wellViewState.trackFontName){
            checked = true
          }
          return <option key={idx} value={value} defaultChecked={checked}>{value}</option>
        })}
      </select>
    </>
  }

  getCorrelationDepthView = () => {
    return <>
      <select name="correlationDepth" onChange={(event)=>{this.setCorrelationDepthType(event)}} value={this.state.wellViewState.correlationDepth}>
        {well_depth.map((value:any,idx:any)=>{
          var checked = false
          if(value.key == this.state.wellViewState.correlationDepth){
            checked = true
          }
          return <option key={idx} value={value.key} defaultChecked={checked}>{value.show}</option>
        })}
      </select>
    </>
  }

  getTree = () =>{
    var trackWidth = this.state.wellViewState.trackWidth
    var divMarginFix = {
      marginBottom:"2%",
      display:"ruby-text"
    };
    return <>
      <ul>
        <li>Plot View<br />
          <ul>
            {/* <li><div style={divMarginFix}>Tracks Width&nbsp;<input type="number" min={50} style={{display:"block", width:"60px"}} value={trackWidth} onChange={(event)=>{this.setTrackWidth(event)}} onKeyUp={(event)=>{this.setTrackWidth(event)}}/>px</div></li> */}
            <li><div style={divMarginFix}>Tracks line thickness&nbsp;<input type="number" min={1} style={{display:"block", width:"40px"}} value={this.state.wellViewState.trackLinesThickness} onChange={(event)=>{this.setTrackLinesThickness(event)}} onKeyUp={(event)=>{this.setTrackLinesThickness(event)}}/>px</div></li>
            {/* <li><div style={divMarginFix}>Tracks GAP&nbsp;<input type="number" min={1} style={{display:"block", width:"40px"}} value={this.state.wellViewState.trackgap} onChange={(event)=>{this.setTrackGap(event)}} onKeyUp={(event)=>{this.setTrackGap(event)}}/>px</div></li> */}
            {/* <li><div style={divMarginFix}>Tracks borders color&nbsp;<input type="color" style={{display:"block"}} value={this.state.wellViewState.trackborderColor} onChange={(event)=>{this.setTracksBorderColor(event)}}/></div></li>
            <li><div style={divMarginFix}>Tracks headers borders color&nbsp;<input type="color" style={{display:"block"}} value={this.state.wellViewState.trackheadersborderColor} onChange={(event)=>{this.setTracksHeaderBorderColor(event)}}/></div></li> */}
            {/* <li><div style={divMarginFix}>Tracks font type&nbsp;{this.getFontsTypeView()}</div></li> */}
            <li><div style={divMarginFix}>Tracks font size&nbsp;<input type="number" min={1} style={{display:"block", width:"40px"}} value={this.state.wellViewState.trackFontSize} onChange={(event)=>{this.setTracksfontsSize(event)}} onKeyUp={(event)=>{this.setTracksfontsSize(event)}}/>px</div></li>
            <li><div style={divMarginFix}>Vertical scale&nbsp;<input type="checkbox" style={{display:"block", width:"40px"}} checked={this.state.wellViewState.trackShowVerticalScale} onChange={(event)=>{this.setShowVerticalScale(event)}} /></div></li>
            {/* <li><div style={divMarginFix}>Tracks zoom&nbsp;<input type="checkbox" style={{display:"block", width:"40px"}} checked={this.state.wellViewState.trackShowZoomBar} onChange={(event)=>{this.setShowTracksZoom(event)}} /></div></li> */}
            <li><div style={divMarginFix}>Tracks height&nbsp;<input type="number" min={1} style={{display:"block", width:"50px"}} value={this.state.wellViewState.trackHeight} onChange={(event)=>{this.setTracksHeight(event)}} onKeyUp={(event)=>{this.setTracksHeight(event)}}/>px</div></li>
          </ul>
        </li>
        <li>Correlation View<br />
              <ul>
                <li><div style={divMarginFix}>Correlation depth&nbsp;{this.getCorrelationDepthView()}</div></li>
              </ul>
        </li>
      </ul>
    </>
  }


  render() {
    var containerFluidStyle = {
      width: '100%',
      paddingLeft: '0',
      paddingRight: '0',
    };

    return (
      <>
      <ReactModal isOpen={this.state.showModal} style={{content:{left:"35%", right:"35%"}}} >
          <div style={{overflowY:"auto",overflowX:"hidden", maxHeight:"80vh"}}>
              <div className='row'>
                  <div className='col-4'>Curve Name</div>
                  <div className='col-4'>Track's Line Color</div>
                  <div className='col-4'>Track's Fill Color</div>
              </div>
              {
                this.state.logNames.map((logName,idx)=>{
                    return (
                    <div className='row'>
                        <div className='col-4'>{logName}</div>
                        <div className='col-4'><input type="color" value={this.state.wellTracksColorTemporary[logName]?.line} onChange={(e)=>{this.setTrackTemporaryColor(logName,"line",e.target.value)}}/></div>
                        <div className='col-4'><input type="color" value={this.state.wellTracksColorTemporary[logName]?.fill} onChange={(e)=>{this.setTrackTemporaryColor(logName,"fill",e.target.value)}}/></div>
                    </div>
                    )
                })
              }
          </div>
            <button onClick={(e)=>{
              this.setState({showModal:false});
              this.render();
              this.setColors(true);
            }}>Save</button>
            <button onClick={(e)=>{
              this.setState({showModal:false});
              this.render();
              this.setColors(false);
            }}>Close</button>
      </ReactModal>
        <div className="container-fluid" style={containerFluidStyle}>
          <div className="row">
            <div className="col-xs-12">
              {this.getTree()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SettingsTabComponent;
