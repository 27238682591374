import React from "react";
import { Component } from "react";
import { DndProvider } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Example from "../DragNDrop/example";
import shortid from "shortid";
import isEqual from 'lodash.isequal';

import "../DragNDrop/styles.css";
import { COLUMN, COMPONENT, ROW } from "../DragNDrop/constants";

interface KogChartsHeaderProps{    
  initialData: any;
  plotConfig?: any,
  context: any,
  tracksData: any,
}

interface KogChartsHeaderState{    
  newData: any;
}

export default class KogChartsHeader extends Component<KogChartsHeaderProps, KogChartsHeaderState>{
  constructor(props: any) {
    super(props);
    this.state = {
      newData: {},    
    }    
  }

  componentWillMount(): void {
    this.setInitialData(this.props.tracksData)    
  }

  componentDidUpdate(prevProps: Readonly<KogChartsHeaderProps>, prevState: Readonly<KogChartsHeaderState>, snapshot?: any): void {
    if(!isEqual(prevProps.initialData, this.props.initialData) || !isEqual(prevProps.tracksData, this.props.tracksData)){
      this.setState({newData: {}}, () => this.setInitialData(this.props.tracksData) )           
    }
    
  }

  drawItem = ( prop: any, color: string, trackMin: number, trackMax: number ) =>{    
    let innerHtml = React.createElement(
      "div", 
      null,       
      React.createElement("span", {className: "spanProp"}, prop), 
      React.createElement(
        "span", 
        {className: "spanMin"},        
        "min:",
        React.createElement("br", null, null),
        trackMin.toFixed(2)
      ), 
      React.createElement(
        "span", 
        {className: "spanMax"},        
        "max:",
        React.createElement("br", null, null),
        trackMax.toFixed(2)
      ),
      
    ) 
    
    return (     
      React.createElement("div", {style: {textAlign: "center", backgroundColor: color, padding: "3px 0", color: this.getContrast(color) }},
        innerHtml
      )
    );

  }

  getContrast = (hexcolor: string) => {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
  
    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
  
    // Convert to RGB value
    let r = parseInt(hexcolor.substr(0,2),16);
    let g = parseInt(hexcolor.substr(2,2),16);
    let b = parseInt(hexcolor.substr(4,2),16);
  
    // Get YIQ ratio
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  
    // Check contrast
    return (yiq >= 128) ? 'black' : 'white';  
  }
  
  setInitialData = (tracksData:any) =>{    
    let newData: any = { "layout": [], "components":{}, "context": this.props.context};

    Object.keys(tracksData).forEach((well: any) => {
      newData["layout"].push({
        "type": ROW,
        "id": tracksData[well].name,
        "children": []
      })

      Object.keys(tracksData[well]["groupData"]).forEach((prop:any) =>{
        const propName = tracksData[well]["groupData"][prop].name;
        const idProp = String(propName + "-" + shortid.generate());
        const trackColor = this.props.context.state.plotData.filter((obj: any) => { return obj.name === propName })[0].lineStyle.color
        const trackMin = tracksData[well]["groupData"][prop].min
        const trackMax = tracksData[well]["groupData"][prop].max

        newData["layout"][well].children.push({
          type: COLUMN,
          id: "column-" + shortid.generate(),
          children:[{
            type: COMPONENT,
            id: idProp,
            //content: this.drawItem(prop, trackColor, trackMin, trackMax)
          }]
        })
        newData["components"][idProp] = { id: idProp, type: "prop", content: this.drawItem(propName, trackColor, trackMin, trackMax) }
      });

    });
    
    // newData["layout"].forEach((well:any)=>{
    //   initialData.forEach((prop:any, idx: number)=>{
    //     const idProp = String(prop.name + "-" + shortid.generate());
    //     const propState = this.props.context.state
    //     const trackColor = propState.plotData.filter((obj: any) => { return obj.name === prop })[0].lineStyle.color 
    //     const trackMin = propState.axisX[idx].min
    //     const trackMax = propState.axisX[idx].max

    //     well["children"].push({
    //       type: COLUMN,
    //       id: "column-" + shortid.generate(),
    //       children:[{
    //         type: COMPONENT,
    //         id: idProp,
    //         //content: this.drawItem(prop, trackColor, trackMin, trackMax)
    //       }]
    //     })
    //     newData["components"][idProp] = { id: idProp, type: "prop", content: this.drawItem(prop, trackColor, trackMin, trackMax) }
    //   })                  
    // });

    this.setState({newData: newData});
  }

  render(){    
    return (
      <DndProvider backend={HTML5Backend as any}>
        <Example data={this.state.newData} plotConfig={this.props.plotConfig} context={this.props.context}/>
      </DndProvider>
    )      
  }  
}