import React, { Component } from 'react';
import './TabComponent.css';
import { StandartColors } from "../../utils/enums/StandartColors";
import SettingsTabComponent from './SettingsTabComponent/SettingsTabComponent';
import ActionsTabComponent from './ActionsTabComponent/ActionsTabComponent';
import DataTabComponent from './DataTabComponent/DataTabComponent';
import { DataTypeTabNames } from '../../utils/enums/DataTypeTabNames';

interface ConfigTabsComponentState {
  activeTab: DataTypeTabNames.DATA | DataTypeTabNames.ACTIONS | DataTypeTabNames.SETTINGS;
}


class ConfigTabsComponent extends Component<{}, ConfigTabsComponentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: DataTypeTabNames.ACTIONS, // Set the default active tab
    };
  }

  handleTabClick = (tabId: any) => {
    this.setState({ activeTab: tabId });
  };

  render() {
    const { activeTab } = this.state;

    var sectionStyle = {
      marginTop: '0px',
      height: '100%'
    };

    var navItemStyle = {      
      color: StandartColors.DarkBlack,
      //padding: '0 15px 0 15px'
    };  

    return (
      <section id="tabs" style={sectionStyle}>
        <div className="row h-100">
          <div className="col-xs-12 d-flex flex-column" style={{maxHeight: '100vh', padding: 0}}>
            <nav>
              <div className="nav nav-tabs nav-fill" id="nav-tab-data" role="tablist">
                <a
                  className={`nav-item nav-link ${activeTab === DataTypeTabNames.DATA ? 'active' : ''}`}                  
                  id="data-tab"
                  onClick={() => this.handleTabClick(DataTypeTabNames.DATA)}
                  aria-controls="Data"
                  aria-selected={activeTab === DataTypeTabNames.DATA}
                >
                  Data
                </a>
                <a
                  className={`nav-item nav-link ${activeTab === DataTypeTabNames.ACTIONS ? 'active' : ''}`}
                  id="actions-tab"
                  onClick={() => this.handleTabClick(DataTypeTabNames.ACTIONS)}
                  aria-controls="Actions"
                  aria-selected={activeTab === DataTypeTabNames.ACTIONS}
                >
                  Actions
                </a>
                <a
                  className={`nav-item nav-link ${activeTab === DataTypeTabNames.SETTINGS ? 'active' : ''}`}
                  id="settings-tab"
                  onClick={() => this.handleTabClick(DataTypeTabNames.SETTINGS)}
                  aria-controls="Settings"
                  aria-selected={activeTab === DataTypeTabNames.SETTINGS}
                >
                  Settings
                </a>                
              </div>
            </nav>
            <div className="tab-content overflow-auto styled-scrollbars" id="nav-tab-data-component" style={{flexGrow: "1", overflowX:'auto'}}>
              <div
                className={`tab-pane fade show ${activeTab === DataTypeTabNames.DATA ? 'active' : ''}`}
                id="Data"
                role="tabpanel"
                style={navItemStyle}
              >
                <DataTabComponent />
              </div>
              <div
                className={`tab-pane fade ${activeTab === DataTypeTabNames.ACTIONS ? 'show active' : ''}`}
                id="Actions"
                role="tabpanel"
                style={navItemStyle}
              >
                 <ActionsTabComponent />
              </div>
              <div
                className={`tab-pane fade ${activeTab === DataTypeTabNames.SETTINGS ? 'show active' : ''}`}
                id="Settings"
                role="tabpanel"
                style={navItemStyle}
              >
                <SettingsTabComponent />
              </div>             
            </div>
          </div>
        </div>        
      </section>
    );
  }
}

export default ConfigTabsComponent;
