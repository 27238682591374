import React, { Component } from "react";
import { Link } from "react-router-dom";
import { userLogin } from "../../services/userService";
import { getUserProjects } from "../../services/projectService";
import ToastHelper from "../../utils/helpers/ToastHelper";
import * as Yup from 'yup';
import { clearCurrentProject,clearProjectToken } from "../../utils/helpers/TokenHelper";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";

interface SignInState {
  username: any
  errorsUsername: any;
  touchedUsername: any;
  password: any;
  errorsPassword: any;
  touchedPassword: any;
}

class SignIn extends Component<{}, SignInState> {

  usernameSchema: any;
  passwordSchema: any;  

  constructor(props: any){
    super(props);
    this.state = {
      username: '',
      errorsUsername: false,
      touchedUsername: false,
      password: '',
      errorsPassword: false,
      touchedPassword: false,
    }

    this.usernameSchema = Yup.object().shape({
      username: Yup.string()
        .min(2, "Mininum 2 characters")
        .required("Required!")
    });
    
    this.passwordSchema = Yup.object().shape({
      password: Yup.string()
        .min(3, "Minimum 3 characters")
        .required("Required!"),
    });    
  }

  clearTokens = () => {
    clearCurrentProject();
    clearProjectToken();
  }

  async login() {    
    userLogin({username: this.state.username, password: this.state.password})
    .then((result) => {
      if(result.error){   
        ToastHelper.warning(result.message).then(message => console.log(message));
        return;
      }
      getUserProjects().then((result2) => {
        //this.clearTokens();
        window.location.href = '/projects';
      })
    })
    .catch((result) => {
      ToastHelper.error("Server Error", result).then(message => console.log(message));
    });
  }

  isValidLUsername = () => {
    return new Promise((resolve, reject) => {
      this.usernameSchema.validate({username: this.state.username}).then((result: any) => {
        resolve(result); // Operation successful!
      }).catch((error: any) => {
        this.setState( {
          errorsUsername: error.errors
        });
      });      
    });     
  }

  isValidLPassword = () => {
    return new Promise((resolve, reject) => {
      this.passwordSchema.validate({password: this.state.password}).then((result: any) => {
        resolve(result); // Operation successful!
      }).catch((error: any) => {
        this.setState( {
          errorsPassword: error.errors
        });
      });      
    });     
  }

  validateAndLogin = () => {    
    this.isValidLUsername().then(() => {
      this.isValidLPassword().then(() => {
        this.login();        
      });
    });   
  }

  handleSubmit = (event: any) => {
    this.setState( {
      errorsPassword: false,
      errorsUsername: false,
    }, () => {
      this.validateAndLogin();
    });     
  }

  handleChangeUsername = (event: any) => {
    var touchedUsername = true;
    if(event.target.value == ''){
      touchedUsername = false;
    }
    this.setState( {
      username: event.target.value,
      touchedUsername: touchedUsername
    });
  }

  handleChangePassword = (event: any) => {
    var touchedPassword = true;
    if(event.target.value == ''){
      touchedPassword = false;
    }
    this.setState( {
      password: event.target.value,
      touchedPassword: touchedPassword
    });
  }

  render() {
    return (
      <>      
        <Card style={{ marginTop: "40%" }}>
          <Row>
            <Col size={12}></Col>
          </Row>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center"></div>

              <Form>
                <FormGroup>
                  <Label style={{ marginBottom: "10px" }}>User Name</Label>
                  <Input
                    bsSize="lg"
                    type="text"
                    name="username"
                    onChange={this.handleChangeUsername}
                    style={{ marginBottom: "20px" }}
                  />
                  {this.state.errorsUsername &&
                     (
                      <p className="text-danger">
                        {this.state.errorsUsername}
                      </p>
                    )}
                </FormGroup>
                <FormGroup>
                  <Label style={{ marginBottom: "10px" }}>Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="password"
                    onChange={this.handleChangePassword}
                  />
                  {this.state.errorsPassword &&
                     (
                      <p className="text-danger">
                        {this.state.errorsPassword}
                      </p>
                    )}
                  <small>
                    <Link to="#">Forgot password?</Link>
                  </small>
                </FormGroup>

                <div className="text-center mt-3">                
                  <Button 
                    color="primary" 
                    size="lg" 
                    onClick={this.handleSubmit}                   
                  >
                    Sign in
                  </Button>                  
                  <br />
                  <br />
                  <Link to="/signup">Sign-Up</Link>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default SignIn;