import { StandartColors } from "../enums/StandartColors";

export default class ToastStyles {
    // Static property
    static success: any = { 
        background: StandartColors.Green,
        color: '#fff',
        fontSize: '20px',    
        padding: '30px 20px',
        position: 'relative', 
        minWidth: '456px',
        maxWidth: '456px',
        right: '200px',
        textAlign: 'left'
      }; 
      
      static warning: any = { 
        background: StandartColors.Yellow,
        color: '#fff',
        fontSize: '20px',    
        padding: '30px 20px',
        position: 'relative', 
        minWidth: '456px',
        maxWidth: '456px',
        right: '200px',
        textAlign: 'left'
      }; 

      static error: any = { 
        background: StandartColors.Red,
        color: '#fff',
        fontSize: '20px',    
        padding: '30px 20px',
        position: 'relative', 
        minWidth: '456px',
        maxWidth: '456px',
        right: '200px',
        textAlign: 'left'
      }; 

      static info: any = { 
        background: StandartColors.Blue,
        color: '#fff',
        fontSize: '20px',    
        padding: '30px 20px',
        position: 'relative', 
        minWidth: '456px',
        maxWidth: '456px',
        right: '200px',
        textAlign: 'left'
      }; 
   
  }