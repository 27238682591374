import WellVersionMetatdata from "./WellVersionMetatdata";
import WellInfoItem from "./WellInfoItem";

export default class WellMetadataContainer {
    versionMetadata: WellVersionMetatdata[];
    deviationsLoaded: boolean;
    id: number;
    wellInfo: WellInfoItem[];
    name:string;

    constructor(data: any) {
        this.versionMetadata = [];      
            
        var versions = data.curves;

        if(!versions){
            versions = {}
        }

        var keys = Object.keys(versions);
        for(var i=0;i<keys.length;i++){
            var key = keys[i];
            var value = versions[key][0]
            var wellVersionMetatdata = new WellVersionMetatdata(value);
            this.versionMetadata.push(wellVersionMetatdata);        
        }
        
        this.deviationsLoaded = data.deviations_loaded;
        this.id = data.id;
        this.wellInfo = [];
        for (const key in data.well_info) {
            if (data.well_info.hasOwnProperty(key)) {
                this.wellInfo.push(new WellInfoItem(data.well_info[key]));
            }
        }
    }
}