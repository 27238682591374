import React, { Component } from 'react';
import { EventNames } from '../../utils/enums/EventNames';
import { NativeSelect, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface PreviewTableProps{    
    listBody: any[];
    listHeaderOptions: any[];
    listDefaultColumns: any[];
    context:string;
}

interface PreviewTableState{    
    listHeaderNew: any[];    
    listBody: any[];
    listHeaderOptions: any[];
    listDefaultColumns: any[];
}

const addTableBody = "AddTableBodyEvent";

export function setTableBody(context:string, dataBody:any){
    const customEvent = new CustomEvent(addTableBody, { detail: {
        context:context,
        dataBody:dataBody
    } 
    });
    document.dispatchEvent(customEvent);
}

export default class PreviewTableComponent extends Component<PreviewTableProps, PreviewTableState>{
    previewTableChangedEvent: string = EventNames.previewTableChangedEvent;
    constructor(props: any) {
        // VERIFICAR QUANTIDADE DE ITENS NAS 3 LISTAS        
        super(props);
        let headers =  this.props.listBody[0].map((option:any, idx:any) => {
                if(this.props.listDefaultColumns[idx]){
                    return this.props.listDefaultColumns[idx]
                }
                return ""

            })
        this.state = {
            listHeaderNew: headers,
            listBody: this.props.listBody,
            listHeaderOptions: this.props.listHeaderOptions,
            listDefaultColumns: this.props.listDefaultColumns           
        };        
    }

    componentDidMount() {  
        document.addEventListener(addTableBody, this.setTableBody);
    }
    
    componentWillUnmount() {
        document.removeEventListener(addTableBody, this.setTableBody);
    }

    setTableBody = (event:any) =>{
        if (event.type !== addTableBody && event.detail.context != this.props.context){
            return;
        }
        let dataset = event.detail;

        let headers:any[] = []
        if (dataset.dataBody.length > 0){
            headers = dataset.dataBody[0].map((option:any, idx:any) => {
                if(this.props.listDefaultColumns[idx]){
                    return this.props.listDefaultColumns[idx]
                }
                return ""
            })
        }       

        this.setState({listBody:dataset.dataBody,listHeaderNew:headers});
    }

    selectChanged(event: React.ChangeEvent<HTMLSelectElement>, idx: number){
        let columns = this.state.listHeaderNew;
        columns[columns.indexOf(event.target.value)] = ""
        columns[idx] = event.target.value;
        this.setState({listHeaderNew:columns});

        const customEvent = new CustomEvent(this.previewTableChangedEvent, { detail: {
            columnNames: this.state.listHeaderNew,
            context:this.props.context        
        }});
        document.dispatchEvent(customEvent);
    }

    render() {       
        return (
            <>
                <TableContainer style={{overflow:"auto", maxHeight:"56vh"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    this.state.listBody[0]?.map((column:any, idx:number) => {
                                        if (idx > 0) {
                                            return (                                            
                                                <TableCell>
                                                    <select value={this.state.listHeaderNew[idx]} onChange={(e) => this.selectChanged(e, idx)}>
                                                        <option value={undefined}>Unknown</option>
                                                    {
                                                        this.state.listHeaderOptions.map(option => {                                               
                                                            if(this.props.listDefaultColumns[idx]){
                                                                return (
                                                                    <option value={option.value} selected={ this.props.listDefaultColumns[idx] == option.value ? true: false}>
                                                                        {option.display}
                                                                    </option>
                                                                )
                                                            } else {
                                                                return (
                                                                    <option value={option.value}>
                                                                        {option.display}
                                                                    </option>
                                                                )
                                                            }                                                       
                                                        })
                                                    }                                                
                                                    </select>  
                                                </TableCell>                                            
                                            )
                                        } else{
                                            return (                                            
                                                <TableCell>                                                     
                                                </TableCell>                                            
                                            )
                                        }
                                        
                                    })
                                }                        
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.listBody?.map(row => {
                                return (
                                    <TableRow>
                                        {
                                            row.map( (column:string) => {
                                                return (
                                                    <TableCell>{column}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>                                    
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>                      
            </>        
        )
    }
}