import { jwtDecode } from "jwt-decode";

export function setUserToken(token: any) {
  if(typeof token != 'string'){
    token = JSON.stringify(token)
  }
  sessionStorage.setItem("userToken", token);    
}

export function setToken(token: any) {
  if(typeof token != 'string'){
    token = JSON.stringify(token)
  }
  sessionStorage.setItem("token", token);    
}

export function setProjectToken(token: any) {
  if(typeof token != 'string'){
    token = JSON.stringify(token)
  } 
  sessionStorage.setItem("projectToken", token);    
}

export function setCurrentProject(info: any) {
  if(typeof info != 'string'){
    info = JSON.stringify(info)
  }  
  sessionStorage.setItem("currentProject", info);    
}

export function setUserInfo(info: any) {  
  if(typeof info != 'string'){
    info = JSON.stringify(info)
  }    
  sessionStorage.setItem("userInfo", info);    
}

export function decodeTokenInfo(info: any) {
  if(info){
    var decoded = jwtDecode(info);
    return decoded
  }
  return null;         
}

export function clearToken() {
  sessionStorage.removeItem("token");
}

export function clearUserToken() {
  sessionStorage.removeItem("userToken");
}

export function clearProjectToken() {
  sessionStorage.removeItem("projectToken");
}

export function clearCurrentProject() {
  sessionStorage.removeItem("currentProject");
}

export function clearUserInfo() {
  sessionStorage.removeItem("userInfo");
}

export const storeSessionData = async (key: any, value: any) => {
  try {
    await sessionStorage.setItem(key, value);
  } catch (error) {
    // Handle errors if needed
    console.error("Error storing session data:", error);
  }
  return;
};

export const retrieveSessionData = (key: string) => {
  try {
      return sessionStorage.getItem(key);
  } catch (error) {
      return null;
  }
};

export function getToken() {  
  var token = retrieveSessionData('token');
  if(!token){
    return null;
  }
  return token;
}

export function isUserLogged() {
  var projectToken = getProjectToken();
  if (projectToken) {
    return true;
  }
  
  var token = retrieveSessionData('token');
  if(!token){
    return false;
  }
  return true;
}

export function getUserUserToken() {
  var info = retrieveSessionData('userToken');
  if(!info){
    return null;
  }
  return info;
}

export function getProjectToken() {
  var info = retrieveSessionData('projectToken');
  if(!info){
    return null;
  }
  return info;
}

export function getUserCurrentProject() {
  var info = retrieveSessionData('currentProject');
  if(!info){
    return null;
  }
  var infoAsObject = JSON.parse(info);
  return infoAsObject;
}

export function getUserInfo() {
  var info = retrieveSessionData('userInfo');  
  if(!info){
    return null;
  }
  var infoAsObject = JSON.parse(info);
  return infoAsObject;
}
