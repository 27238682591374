//External packages
import React, { Component } from 'react';

interface TimerState {
    timer:number
}

interface TimerProps {
}

export class Timer extends Component<TimerProps, TimerState> {
    constructor(props:TimerProps){
        super(props);
        this.state = {
            timer:0
        }
        setInterval(()=>{this.upTimer()},1000);
    }

    upTimer = () =>{
        this.setState({timer:this.state.timer+1});
    }

    formater = (timer:number) =>{
        return timer<10?`0${timer}`:timer
    }

    render(): React.ReactNode {
        const hour = Math.floor(this.state.timer / 3600);
        const minutes = Math.floor(this.state.timer / 60) - (hour*60);
        const seconds = Math.floor(this.state.timer - hour * 3600 - minutes * 60);
        return(
            <>
            {this.formater(hour)}:{this.formater(minutes)}:{this.formater(seconds)}
            </>
        )
    }
}