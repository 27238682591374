export default class TreeViewLog {
    checked: boolean = false;
    label: string = '';
    id: string = '';
    visible: boolean = true;

    constructor(label: any, parent: any, curveId: any , versionId: any) {
      this.label = label;
      this.id =  parent.id + ';{"log":{ "label": "' + label + '", "curve_id": "'+ curveId + '", "version_id": "' + versionId +'"}}';
    }    

    toString() {
      return this.id;
    } 
    
  }