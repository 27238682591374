// Header
export const well_name = { display: 'WellName', value: 'well' };
export const uwi = { display: 'Unique Well Id', value: 'UWI' };
export const surface_x = { display: 'Surface X', value: 'xColumn' };
export const surface_y = { display: 'Surface Y', value: 'yColumn' };
export const MD = { display: 'Measure Depth', value: 'mdColumn' };
export const KB = { display: 'Kelly Bushing(KB)', value: 'KB' };
export const RT = { display: 'Rotatory Table', value: 'RT' };
export const GL = { display: 'Ground Level', value: 'GL' };
export const TD = { display: 'Total Depth', value: 'TD' };
export const lat = { display: 'Latitude', value: 'lat' };
export const long = { display: 'Longitude', value: 'long' };

// Markers
export const marker = { display: 'Marker Name', value: 'marker' };

// Deviation
export const TVD = { display: 'TVD', value: 'TVD' };
export const x = { display: 'X', value: 'x' };
export const y = { display: 'Y', value: 'y' };
export const z = { display: 'Z', value: 'z' };
export const DX = { display: 'DX', value: 'DX' };
export const DY = { display: 'DY', value: 'DY' };
export const AZIM_TN = { display: 'AZIM_TN', value: 'AZIM_TN' };
export const INCL = { display: 'INCL', value: 'INCL' };
export const DLS = { display: 'DLS', value: 'DLS' };
export const AZIM_GN = { display: 'AZIM_GN', value: 'AZIM_GN' };

export interface AttributeParam{
    display:string,
    value:string
}

export default class AttributeParameters{
    well_header: AttributeParam[] = [
        well_name,
        uwi,
        surface_x,
        surface_y,
        MD,
        KB,
        RT,
        GL,
        TD,
        lat,
        long,
    ]

    well_marker: AttributeParam[] = [
        well_name,
        MD,
        marker
    ]

    deviation: AttributeParam[] = [
        MD,        
        TVD,
        x,
        y,
        z,
        DX,
        DY,
        AZIM_TN,
        INCL,
        DLS,
        AZIM_GN,
    ]
}