import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { COLUMN, COMPONENT } from "./constants";
import DropZone from "./DropZone";
import Component from "./Component";


const Column = ({ data, components, handleDrop, path, plotConfig }) => {
  const ref = useRef(null);
  const style = plotConfig;

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: {     
      id: data.id,
      children: data.children,
      path
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  const width = isDragging? "0px": "initial"; // TODO: Pegar o offset da escala vertical diretamente do tracks.tsx
  const position = isDragging? "absolute": "initial";
  drag(ref);

  const renderComponent = (component, currentPath) => {
    return (
      <Component
        key={component.id}
        data={component}
        components={components}
        path={currentPath}
      />
    );
  };

  return (
    <div
      ref={ref}
      style={{ opacity, width }}
      className="base draggable propertyGroup"
    >
      {/* {data.id} */}
      {data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length
              }}
              onDrop={handleDrop}
              className={"horizontalDrag"}
              accepts={currentPath.split("-")[0] == path.split("-")[0]? [COLUMN, COMPONENT]: []}
            />            
            {renderComponent(component, currentPath)}
          </React.Fragment>
        );
      })}
      <DropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length
        }}
        onDrop={handleDrop}
        className={"horizontalDrag"}
        accepts={[COLUMN, COMPONENT]}
        isLast
      />      
    </div>
  );
};
export default Column;
