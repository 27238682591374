import React, { Component } from 'react';
// import Papa from "papaparse";
import ToastHelper from '../../../utils/helpers/ToastHelper';
import ParserParameters, {OneSpace, DoubleQuotation, Hash, Meter, MD, m9999_99} from '../../ParserFields/ParserValues';
import FixWellInfosTable, { updateTable } from '../../FixWellInfosTable/FixWellInfosTable';
// import PreviewTableComponent, { setTableBody } from '../../PreviewTable/PreviewTableComponent';
// import ParserFieldsComponent from '../../ParserFields/ParserFields';
// import ListFilesComponent, { addFiles } from '../../ListFiles/ListFilesComponent';
// import FileUploaderComponent from '../../FileUploader/FileUploaderComponent';
import { StandartColors } from '../../../utils/enums/StandartColors';
import { EventNames } from '../../../utils/enums/EventNames';
import { uploadWellMarkers } from '../../../services/stratigraphicService';
import AttributeParameters, {well_name, marker, MD as MD_header} from '../../PreviewTable/TableOptions';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';
import LoadFileComponent from '../../LoadFileComponent/LoadFileComponent';
import SelectDataComponent from '../../SelectDataComponent/SelectDataComponent';

interface WellMarkersComponentState {
  activeTab: string;
  fileList: any[];  
  dataList: any[];
  newWellsNames: any;
  startLine: number;
  delimiter: string;
  qualifier: string;
  comment: string;
  depthUnit: string;
  depthType: string;
  noValue: string;
  headersColumns: any;
  fileData: any[]
}

interface WellMarkersComponentProps {
  
}

enum TabNames{
  uploadFile = "Load File",
  selectData = "Select Data",
  correctWellInfo = "Correct Well Info"
}

const tabOrder: string[] =[
  TabNames.uploadFile,
  TabNames.selectData,
  TabNames.correctWellInfo,
]

const CONTEXT = 'wellMarkers';

export default class WellMarkersComponent extends Component<WellMarkersComponentProps, WellMarkersComponentState> {    
  changeWellsNamesEvent = EventNames.updatedWellNamesEvent;
  previewTableChangedEvent: string = EventNames.previewTableChangedEvent;
  loadFileComponentEvent: string = EventNames.LoadFileComponentEvent;
  SelectDataComponentEvent: string = EventNames.SelectDataComponentEvent;  
  SelectDataComponentClickUploadButton: string = EventNames.SelectDataComponentClickUploadButton;  
  SelectDataComponentParserInfoEvent: string = EventNames.SelectDataComponentParserInfoEvent; 

    constructor(props: any) {
      super(props);
      this.state = {
        activeTab: TabNames.uploadFile,
        fileList: [],
        startLine: 1,
        delimiter:OneSpace.value,
        qualifier:DoubleQuotation.value,
        comment:Hash.value,
        depthUnit:Meter.value,
        depthType:MD.value,
        noValue:m9999_99.value,
        headersColumns:{
          well:1,
          marker:2,
          mdColumn:3,
        },
        dataList:[],
        newWellsNames:{},
        fileData:[],
      };
    }

    componentDidMount() {  
      document.addEventListener(this.loadFileComponentEvent, this.setFiles);
      document.addEventListener(this.SelectDataComponentEvent, this.receiveDataset);
      document.addEventListener(this.SelectDataComponentClickUploadButton, this.receiveClickUploadButton);
      document.addEventListener(this.SelectDataComponentParserInfoEvent, this.receiveParserInfo);
      document.addEventListener(this.changeWellsNamesEvent, this.fixWellsNames);      
    }
  
    componentWillUnmount() {      
      document.removeEventListener(this.loadFileComponentEvent, this.setFiles);
      document.removeEventListener(this.SelectDataComponentEvent, this.receiveDataset);
      document.removeEventListener(this.SelectDataComponentClickUploadButton, this.receiveClickUploadButton);
      document.removeEventListener(this.SelectDataComponentParserInfoEvent, this.receiveParserInfo);
      document.removeEventListener(this.changeWellsNamesEvent, this.fixWellsNames);     
    }

    fixWellsNames =(event:any) => {
      if (event.type !== this.changeWellsNamesEvent && event.detail.context != CONTEXT){
        return;
      }
      let dataset = event.detail;
      let prevState = this.state.newWellsNames;
      prevState = this.processNewWellsNames(dataset.wellInfosContents,prevState);
      this.setState({newWellsNames:prevState});
    }
  

    processNewWellsNames (dataset:any, prevState:any){
      dataset.forEach((row:string[],rowIdx:number)=>{
        if(!Object.keys(prevState).includes(row[0])){
          prevState[row[0]] = {};
        }
        if(!prevState[row[0]][row[1]]){
          prevState[row[0]][row[1]] = row[2];
        }
      })
      return prevState;
    }

    setFiles = (event:any) => {
      if (event.type !== this.loadFileComponentEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;           
      this.setState({fileList:dataset.fileList},()=>{});
    }

    fixWellsInfos = (prev_data:any[], filename?:string) =>{
      let wells_infos = prev_data.map((data:any,dataIdx:number)=>{
        const set_filename = filename?filename:this.state.dataList[dataIdx][0];
        let machlee_name = data[this.state.headersColumns.well];
        if(this.state.dataList.length > 0 && this.state.dataList[dataIdx][2]){
          machlee_name = this.state.dataList[dataIdx][2];
        }
        return [set_filename, data[this.state.headersColumns.well], machlee_name];
      })
      return wells_infos;
    }

    receiveDataset = (event:any) => {
      if (event.type !== this.SelectDataComponentEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;
      this.setState({dataList: dataset.dataList})
      updateTable(dataset.dataList, CONTEXT);
    }
  
    receiveClickUploadButton = (event:any) => {
      if (event.type !== this.SelectDataComponentEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;
      // this.setState({dataList: dataset.dataList})
      // updateTable(dataset.dataList, CONTEXT);
      this.upload();
    }
  
    receiveParserInfo = (event:any) => {
      if (event.type !== this.SelectDataComponentParserInfoEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;
      this.setState({
        dataList: dataset.dataList, 
        newWellsNames: dataset.newWellsNames, 
        fileData: dataset.fileData,        
        delimiter:dataset.delimiter,
        qualifier:dataset.qualifier,
        comment:dataset.comment,
        startLine:dataset.startLine, 
        depthUnit:dataset.depthUnit,
        depthType:dataset.depthType,
        noValue:dataset.noValue,        
      })
      updateTable(dataset.dataList, CONTEXT);              
    }


    createDefaultColumns = () =>{
      let defaultColumns:any = {};   
  
      defaultColumns[this.state.headersColumns.well] = well_name.value
      defaultColumns[this.state.headersColumns.marker] = marker.value
      defaultColumns[this.state.headersColumns.mdColumn] = MD_header.value
      
      return defaultColumns
    }
    
    setTab = (tabName:string) => {
      this.setState({activeTab:tabName});
    }


    tabHeader = () =>{
      return (
        <div className="process-nav">{tabOrder.map((currentTab,idx)=>{
          return (
            <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
              {currentTab}
            </div>
          )
        })}
        </div>
      )
    }

    /**
   * Upload data
   */
  upload = () =>{

    if(this.state.fileList.length == 0){
      ToastHelper.warning("There is no information to load, please add files in 'Load File' tab and try again.");
      return;
    }

    const wells_names = this.state.dataList.map((row,rowIdx)=>{
      return row[1];
    });

    let new_well_names:any[] = [];

    Object.keys(this.state.newWellsNames).forEach((filename:string,idx:number)=>{
      Object.keys(this.state.newWellsNames[filename]).forEach((old_wellName:string,wellIdx)=>{
        new_well_names.push({oldName:old_wellName,newName:this.state.newWellsNames[filename][old_wellName]});
      });
    });

    var data = new FormData();
    for(var idx = 0; idx<this.state.fileList.length; idx++){
      data.append("file", this.state.fileList[idx]);
    }

    if(this.state.fileData.length == 0){
      ToastHelper.warning("Could not parse file(s) correctly, please check parameters in 'Select Data' tab and try again.");
      return;
    }

    let dataset = {
        wellnamecol: this.state.headersColumns.well-1,
        top_col: this.state.headersColumns.marker-1,
        depth_col: this.state.headersColumns.mdColumn-1,
        delimiter: this.state.delimiter,
        qualifier: this.state.qualifier,
        start_line: this.state.startLine,
        comment: this.state.comment,
        header: false,
        operation: "U"
    }
    data.append("data",JSON.stringify(dataset))

    uploadWellMarkers(data).then((response_data)=>{
      if(!response_data.response.error){
        ToastHelper.success("Upload well markers was successful!");
      }
      else{
        ToastHelper.error("Error when upload well(s) marker(s). Please check parameters or file content." , ()=>{
          console.log(response_data.response.error);
        });
      }
      refreshDataTab()
    }).catch((error)=>{
      ToastHelper.error("Error when upload well(s) path(s) on file(s): " + error, ()=>{
        console.log(error);
      });
    })
  }

    tabsView = () =>{

  
      return (
        <section>
          <div className="row mb-3">
            
            {this.tabHeader()}
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="tab-content" id="nav-tab-map-view-component">
                <div
                  className={`tab-pane h-100  fade show ${this.state.activeTab === TabNames.uploadFile ? 'active' : ''}`}                  
                  role="tabpanel"                  
                >
                  <LoadFileComponent context={CONTEXT}/>
                </div>
                <div
                  className={`tab-pane h-100  fade show ${this.state.activeTab === TabNames.selectData ? 'active' : ''}`}                  
                  role="tabpanel"                  
                >
                  <SelectDataComponent context={CONTEXT} 
                    headersColumns={this.state.headersColumns} 
                    defaultColumns={this.createDefaultColumns()} 
                    delimiter={this.state.delimiter} 
                    qualifier={this.state.qualifier} 
                    comment={this.state.comment}
                    depthUnit={this.state.depthUnit}
                    depthType={this.state.depthType}
                    noValue={this.state.noValue}
                    listHeaderOptions={new AttributeParameters().well_marker}
                  />
                </div>
                <div
                  className={`tab-pane h-100  fade show ${this.state.activeTab === TabNames.correctWellInfo ? 'active' : ''}`}                  
                  role="tabpanel"                  
                >
                  <div className='row'>
                    <div className='col'>
                      <b>Correct information read in file:</b>
                    </div>
                    <div className='col-2 mb-3'>
                      <input type="button" className="btn btn-dark" value="Load" onClick={()=>{this.upload()}} />
                    </div>
                  </div>
                  <FixWellInfosTable wellsData={this.state.dataList} context={CONTEXT} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )
  
    }
  
    render(){
      return(
        <>
          {this.tabsView()}
        </>
      )
    }
  }