import React, { Component } from 'react';
import "./style.css";
import { getUserProjects, loadProject, newProject } from '../../services/projectService';
import { getUserInfo } from '../../utils/helpers/TokenHelper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Project{
    id: number,
    title: string,
    description: string,
    projectType:string
}

interface UserProjectViewState{
    currentProject: number,
}

interface UserProjectViewProps{
    projects: Project[],
}

const PROJECTDEFAULTS:any = {
    autolog:{
        border: '1px solid #E1E3E7',
        borderLeft: '5px solid red',
        backgroundColor: "white",
        cursor:"pointer"
    }
}

const RELOADPROJECTSLISTEVENT = 'reloadProjectsListEvent'

class ProjectsView extends Component<UserProjectViewProps, UserProjectViewState>{

    constructor(props:any){
        super(props);
        this.state = {
            currentProject:-1
        };
    }

    // componentDidMount() {  
    //     document.addEventListener(RELOADPROJECTSLISTEVENT, this.reloadProjectsList);
    // }
    
    // componentWillUnmount() {
    //     document.removeEventListener(RELOADPROJECTSLISTEVENT, this.reloadProjectsList);
    // }

    // reloadProjectsList = (event:any) =>{
    //     if (event.type !== RELOADPROJECTSEVENT){
    //         return;
    //     }

    //     const dataset = event.detail;
    // }

    setCurrentProject = (projectNumber:number) =>{
        this.setState({currentProject:projectNumber});
    }

    loadProject = (projectNumber:number) =>{
        loadProject(this.props.projects[projectNumber].id).then((response)=>{
            window.location.href = '/';
        });
    }

    render(): React.ReactNode {
        return(
            <>
            <div className='row mt-3'>
                <div className='col'>
                <div className='row mt-3'><div className='col-1 offset-1'><h2>Recents</h2></div></div>
                <div className='row mt-3' style={{ maxHeight:"500px", minHeight:"400px" }}> 
                    <div className='col-11'>
                        {
                            this.props.projects.map((project:Project, projectIdx:number) => {
                                return (
                                    <div className='row' style={{cursor:"pointer"}}>
                                        <div className='col'>
                                            <div className='row'><div className='col-11 offset-1 project-item pt-3 pb-3' onClick={(event)=>{this.loadProject(projectIdx)}}>{project.title}</div></div>
                                            {/* <div className='row'><div className='col'>{project.description}</div></div> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                </div>
                {/* { this.state.currentProject > -1 && (
                    <div className='col-6' style={{borderLeft: '2px solid #E1E3E7',}}>
                        <div className='row'><h2>{this.props.projects[this.state.currentProject].title}</h2></div>
                        <div className='row'><article>{this.props.projects[this.state.currentProject].description}</article></div>
                        <div className='row mt-3'>
                            <div className='col-1'><input type="button" className='btn btn-primary' value="Load" onClick={(event)=>{this.loadProject(this.state.currentProject)}}/></div>
                            <div className='col-1 offset-1'><input type="button" className='btn btn-danger' value="Delete"/></div>
                        </div>
                    </div>
                ) } */}
            </div>
            </>
        )
    }

}

interface NewProjectViewProps{
}

interface NewProjectViewState{
    name:string,
    description:string,
}


class NewProjectFormView extends Component<NewProjectViewProps, NewProjectViewState>{

    constructor(props:any){
        super(props);
        this.state = {
            name:"",
            description:""
        };
    }

    setProjectName = (projectName:string) =>{
        this.setState({name:projectName});
    }

    setDescription = (description:string) =>{
        this.setState({description:description});
    }

    createProject = () =>{
        const dataset = {
            project_name:this.state.name,
            project_description:this.state.description,
        }
        newProject(dataset).then((response)=>{
            reloadProjectsEvent();
            window.location.href = '/';
        })
    }


    render(): React.ReactNode {
        return(
            <div style={{minWidth:'300px'}}>
                <div className='row'>
                    <div className='col'>
                        Name: <input onChange={(event)=>{this.setProjectName(event.target.value)}} />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col' style={{verticalAlign:'text-top'}}>
                        Description:
                    </div>
                    <div className='col-8'>
                        <textarea onChange={(event)=>{this.setDescription(event.target.value)}} />
                    </div>
                </div>
                <div className='row mt-1'>
                    <div className='col'>
                        <input type="button" className='btn btn-secondary' value="Create" onClick={(event)=>{this.createProject()}}/>
                    </div>
                </div>
            </div>
        )
    }
}

const TABS = [
    { display:"Load a project" },
    { display:"Create a project" },
    { display:"Version" },
    { display:"Help" },
]

interface UserProjectState{
    projects: Project[],
    currentTab:string,
    createProjectDialog:boolean,
}

interface UserProjectProps{

}

const RELOADPROJECTSEVENT = 'reloadprojectsevent'

function reloadProjectsEvent(){
    const customEvent = new CustomEvent(RELOADPROJECTSEVENT, { detail: {} 
    });
    document.dispatchEvent(customEvent);
  }

export default class UserProjects extends Component<UserProjectProps, UserProjectState> {
    constructor(props:any){
        super(props);
        this.state = {
            currentTab: TABS[0].display,
            projects: [],
            createProjectDialog:false
        }
        this.getProjects()
    }

    componentDidMount() {  
        document.addEventListener(RELOADPROJECTSEVENT, this.reloadProjects);
    }
    
    componentWillUnmount() {
        document.removeEventListener(RELOADPROJECTSEVENT, this.reloadProjects);
    }

    reloadProjects = (event:any) =>{
        if (event.type !== RELOADPROJECTSEVENT){
            return;
        }
        this.getProjects();
    }

    getProjects = () =>{
        getUserProjects().then((response)=>{
            let projects = []
            projects = response.map((project:any)=>{
                return {
                    id: project.project_id,
                    title: project.name,
                    description: project.description,
                    projectType: "autolog"
                }
            });
            this.setTab(projects.length > 0?0:1)
            this.setState({projects:projects});
        });
    }

    setTab = (tabIdx:number) =>{
        if(tabIdx != 1){
            this.setState({currentTab:TABS[tabIdx].display});
        }
        else{
            this.setState({createProjectDialog:true});
        }
    }

    renderCreateProjectDialog = () =>{
            return(
                <Dialog open={this.state.createProjectDialog} onClose={()=>{this.setState({createProjectDialog:false});}}>
                    <DialogTitle>Project Options</DialogTitle>
                    <DialogContent dividers>
                        <NewProjectFormView />
                    </DialogContent>
                </Dialog>
            )
    }

    render(): React.ReactNode {
        document.body.style.backgroundColor = "#000";
        return(
            <>
                <div className='projectPage'>
                    <div className='row nav-color'>
                        <div className='col-2' style={{borderRight:"1px solid #E1E3E7"}}>
                            <h1>Autolog</h1>
                        </div>
                        <div className='col'>
                            <nav className='navbar nav-color pt-0 pb-0'>
                                
                            </nav>
                        </div>
                    </div>
                    <div className='row' style={{height:"90vh"}}>
                        <div className='col-2' style={{borderRight:"1px solid #E1E3E7"}}>
                            <div className='row mt-3'>
                                <div className='col mt-3'>
                                    { TABS.map((tab:any,tabIdx:number)=>{
                                        return(
                                            <div className={`row ${tab.display == this.state.currentTab?'selected-menu-item':'' }`}><div className='col pt-2 pb-2' style={{cursor:'pointer'}} onClick={(event)=>{this.setTab(tabIdx)}}><b>{tab.display}</b></div></div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row mt-3'><div className='col'><b>Welcome to Autolog, {getUserInfo().name}!</b></div></div>
                            <div className='row'>
                                <div className='col'>
                                    { (this.state.currentTab == TABS[0].display) && (<ProjectsView projects={this.state.projects} />) }
                                    {/* { this.state.currentTab == TABS[1].display && <NewProjectFormView /> } */}
                                </div>
                            </div>
                        </div>
                    </div>
                        {this.renderCreateProjectDialog()}
                </div>                
            </>
        )
    }
}