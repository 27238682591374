import TreeViewLog from "./TreeViewLog";

export default class TreeViewWell {
  logs: Array<TreeViewLog> = [];
  checked: boolean = false;
  label: string = ''
  id: string = '';
  visible: boolean = true;
  
  constructor(well?: any, label?: string) {
    if(label){
      this.label = label;
      this.setId(label, label);//set here because undefined well may happen
    }
    
    if(well){
      this.setId(label, well.wellId);
      for(let i=0;i<well.curves.length;i++){
        const logs = well.curves[i].logs;
        
        for(let i=0;i<logs.length;i++){
          const label = logs[i];
          if(label !== 'MD'){
            this.logs.push(new TreeViewLog(label, this, label, well.versionId));
          }      
        }  
      }
    }
  }

  setId(label:string|undefined , well_id: string|undefined){
    if(!label){
      label = '';
    }
    if(!well_id){
      well_id = '';
    }
    this.id = '{"well":{ "label": "' + label + '", "id": "'+ well_id +'"}}';
  }

  toString() {
    return this.id;
  } 

  markNode: any = (value: boolean) => {
    console.log('well representation marked', value);
    this.checked = value;
  }  
}