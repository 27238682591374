/* eslint-disable */

import { RestApiService } from "../services/RestApiService";
import { Host_Project } from "../Config/Host";
import { 
  getProjectToken, 
  setCurrentProject, 
  setProjectToken, 
  getToken,
  isUserLogged,
  getUserInfo,
  getUserUserToken,
} from '../utils/helpers/TokenHelper';

import '../utils/helpers/AxiosInterceptor';

export async function getUserProjects() {
  return new Promise((resolve, reject) => { 
    var token = getToken();
    if(!token){
      token = getProjectToken();
    }
    var userId = getUserInfo().id;
  
    RestApiService.get(`${Host_Project}/project/user/${userId}`, token)
    .then(projectResponse => {
      var projects = projectResponse.projects;
      if(projects.length == 0){
        resolve([]);
        return;
      }
      resolve(projects);
      // loadProject(projects[0].project_id, token).then(result => {
      //   resolve(projects);
      // })
      // .catch(error => {
      //   reject(error);
      // });
    })
    .catch(error => {
      reject(error);
    }); 
  });
}

export async function saveUserConfs(params) {
  return new Promise((resolve, reject) => {
      var token = getProjectToken();
      RestApiService.post(`${Host_Project}/project/confs/`, params, token)
      .then(response => {
        // setCurrentProject(projectId);
        // setProjectToken(response.token);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function getUserConfs() {
  return new Promise((resolve, reject) => {
      var token = getProjectToken();
      RestApiService.get(`${Host_Project}/project/confs/`, token)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function newProject(params) {
  return new Promise((resolve, reject) => {
      var token = getToken();
      RestApiService.post(`${Host_Project}/project/new/`, params, token)
      .then(response => {
        setCurrentProject(response.project_id);
        setProjectToken(response.token);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export async function loadProject(projectId) {
  return new Promise((resolve, reject) => {    
      RestApiService.get(`${Host_Project}/project/load/${projectId}`, getToken())
      .then(response => {
        setCurrentProject(projectId);
        setProjectToken(response.token);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}