import WellMetadataContainer from "../utils/models/WellMetatada/WellMetadataContainer";

export default class GlobalWellMetadataInfo {
    private static instance: GlobalWellMetadataInfo;
    private data: WellMetadataContainer;

    private constructor(data: WellMetadataContainer) {
        this.data = data;
    }

    static getInstance(data: WellMetadataContainer): GlobalWellMetadataInfo {
        if (!GlobalWellMetadataInfo.instance) {
            GlobalWellMetadataInfo.createNewInstance(data);
        }
        return GlobalWellMetadataInfo.instance;
    }

    static createNewInstance(newData: WellMetadataContainer): void {
        GlobalWellMetadataInfo.instance = new GlobalWellMetadataInfo(newData);
    }

    static getDataFromInstance(): any {
        return GlobalWellMetadataInfo.instance?.data;
    }

    getData(): WellMetadataContainer {
        return this.data;
    }
}