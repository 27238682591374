import React, { Component } from 'react';
import {
  FaChevronRight,
  FaChevronDown,
} from "react-icons/fa";

require('./checkbox-tree.css');

interface CollapsibleTreeViewState {
    isExpanded: boolean;
}

interface CollapsibleTreeViewProps {
  childrenComponent: any;
  title: any;
}

export default class CollapsibleTreeView extends Component<CollapsibleTreeViewProps, CollapsibleTreeViewState>{
  constructor(props: any) {
    super(props);
    this.state = {
      isExpanded: true
    };
  }

  toggleExpand = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  };

  getExpanded = (isExpanded: boolean) => {

    if(isExpanded) {
      return (
        <FaChevronDown className="rct-icon rct-icon-expand-all" />
      );
    } else {
      return (
        <FaChevronRight className="rct-icon rct-icon-collapse-all" />
      );
    }
  }

  render() {
    const { isExpanded } = this.state;
    
    // if(this.props.childrenComponent.props.nodes.length == 0){
    //   return <></>
    // }

    return (
      <div style={{padding: "5px", color:"black"}}>
        {/* {
          this.props.childrenComponent.props.nodes.length > 0 && (
            <div className="well-node" onClick={this.toggleExpand}>         
              <span> {this.getExpanded(isExpanded)} </span>
              {this.props.title}
            </div>
          )
        } */}
        
        {isExpanded && (
          <div className="well-details">
            {this.props.childrenComponent}            
          </div>
        )}
      </div>
    );
  }
}
