export enum StandartColors {
  DarkerWhite = '#f0f0f0',
  LightGray = '#eee',
  LightGray2 = '#E1E3E7',
  DarkBlack ='#28302c',
  LightBlack = '#1c1c24',
  Yellow = '#f8c40c',
  Red = '#e84c3c',
  Blue = '#389cdc',
  Green = '#08bc0c',
  Orange = '#F78812',
  DarkRed = '#7F0037',
  OtherLightGray = '#EEEFF0',
  DarkGray = '#5A5A5A',
  White = '#FFFFFF',
  Black = '#000000'
}