import React, { Component } from 'react';

import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface InfiniteProgressBarState {
}

interface InfiniteProgressBarProps {
    title:string
}

export class InfiniteProgressBar extends Component<InfiniteProgressBarProps, InfiniteProgressBarState> {
    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    render(): React.ReactNode {
        return(
            <>
                <div className='row'>
                    <div className='col' style={{textAlign:"center"}}>
                        <h1>{this.props.title}</h1>
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col d-flex justify-content-center'>
                        <CircularProgress size={200} color="inherit" />
                    </div>
                </div>
            </>
        )
    }
}