export class RestApiService {

  static getHeader(token: any | null){
    var header: any = null
    
      if (token) {
        header = {
          'Content-Type': 'application/json',
          'NautilusAuthorization': `NautilusToken ${token}`,
          'MachleeAuthorization': `MachleeToken ${token}`,
        };
      } else {
        header = {
          'Content-Type': 'application/json'
        };
      }

      return header
  }

    /**
     * @example
     * RestApiService.get('https://api.example.com/data/1')
     *   .then(data => console.log('Response:', data))
     *   .catch(error => console.error('Error:', error));
     */
    static get(url: string, token: any = null) {
      var header: any = RestApiService.getHeader(token);

      return fetch(url, {
        method: 'GET',
        headers: header
        })
        .then(response => response.json())
        .catch(error => {
          console.error('Error fetching data:', error);
          throw error;
        });
    }

    /**
     * @example
     * RestApiService.post('https://api.example.com/data/1', { x: 'str1', y: 'str2' })
     *   .then(data => console.log('Response:', data))
     *   .catch(error => console.error('Error:', error));
     */  
    static post(url: string, data: any, token: any = null) {
      var header: any = RestApiService.getHeader(token);

      return fetch(url, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .catch(error => {
          console.error('Error posting data:', error);
          throw error;
        });
    }

    /**
     * @example
     * RestApiService.put('https://api.example.com/data/1', { x: 'str1', y: 'str2' })
     *   .then(data => console.log('Response:', data))
     *   .catch(error => console.error('Error:', error));
     */    
    static put(url: string, data: any, token: any = null) {
      var header: any = RestApiService.getHeader(token);

      return fetch(url, {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .catch(error => {
          console.error('Error updating data:', error);
          throw error;
        });
    }
  
    /**
     * @example
     * RestApiService.delete('https://api.example.com/data/1')
     *   .then(data => console.log('Response:', data))
     *   .catch(error => console.error('Error:', error));
     */    
    static delete(url: string, data: any = null, token: any = null) {
      var header: any = RestApiService.getHeader(token);
      if(!data){
        data = {}
      }

      return fetch(url, {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(data),
      })
        .then(response => response)
        .catch(error => {
          console.error('Error deleting data:', error);
          throw error;
        });
    }

    static upload(url: string, data: any, token: any = null) {
      var header: any = RestApiService.getHeader(token);        
      delete header['Content-Type'];

      return fetch(url, {
        method: 'POST',
        headers: header,
        body: data
      })
        .then(response => response.json())
        .catch(error => {
          console.error('Error posting data:', error);
          throw error;
        });
    }

  }
 
  