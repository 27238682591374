import TreeViewWell from "./TreeViewWell";

class TreeViewRepresentation {
  wells: Array<TreeViewWell> = [];
  private static instance: TreeViewRepresentation | null = null;

  private constructor(wells: any) {
    this.wells = [];
    for (let i = 0; i < wells.length; i++) {
      try {
        const well = wells[i];
        if(well.curves[0].logs.length > 0){
          this.wells.push(new TreeViewWell(well, well.titulo));
        }        
      } catch (error) {
        console.log(error);
      }
    }
  }

  public static getInstance(wells: any, newInstance:boolean = false): TreeViewRepresentation {
    if (!TreeViewRepresentation.instance || newInstance) {
      TreeViewRepresentation.instance = new TreeViewRepresentation(wells);
    }
    return TreeViewRepresentation.instance;
  }
}

export function createTreeViewRepresentation(wells: any, newInstance:boolean = false): TreeViewRepresentation {  
  return TreeViewRepresentation.getInstance(wells,newInstance);
}
