//External packages
import React, { Component } from 'react';

//Internal packages
import "./OptionsCorrelationTab.css";
import { CorrelationTabEvents } from '../../../../../utils/enums/CorrelationsTab';

interface OptionsCorrelationTabState {
    logsNames: string[],
    logsOptions: any,
    timerToSend:any,
}

interface OptionsCorrelationTabProps {
    logsNames: string[],
    logsOptions: any
}

let samplingMethods = [
    {key:"Default Method", show:"Default Method"},
    {key:"Upscaling", show:"Upscaling"},
    {key:"Interpolation", show:"Interpolation"}
]

class OptionsCorrelationsTab extends Component<OptionsCorrelationTabProps, OptionsCorrelationTabState> {

    optionUpdateEvent: string = CorrelationTabEvents.correlationOptionsUpdate;

    constructor(props:OptionsCorrelationTabProps){
        super(props);
        let optionsNames = Object.keys(this.props.logsOptions);
        let options = this.props.logsOptions;
        if(optionsNames.length != this.props.logsNames.length){
            this.props.logsNames.forEach((logName:any,idx:any)=>{
                if(!optionsNames.includes(logName)){
                    options[logName] = {logWeights:1.0, norm:0.125, sampling:samplingMethods[0].key};
                }
            });
        }
        this.state = {
            logsNames: props.logsNames,
            logsOptions: options,
            timerToSend:null
        };
        this.setTimerToSendChanges();
    }

    changeCurveWeights = (value:number, logName:any, callback:any) =>{
        let options = this.state.logsOptions;
        options[logName].logWeights = value;
        this.setState({logsOptions:options},()=>{callback()});
        this.setTimerToSendChanges();
    }

    changeCurveNorms = (value:number, logName:any, callback:any) =>{
        let options = this.state.logsOptions;
        options[logName].norm = value;
        this.setState({logsOptions:options}, ()=>{callback()});
        this.setTimerToSendChanges();
    }

    changeCurveSamplingMethod = (value:string, logName:any) =>{
        let options = this.state.logsOptions;
        options[logName].sampling = value;
        this.setState({logsOptions:options});
        this.setTimerToSendChanges();
    }

    setTimerToSendChanges = () => {
        if(this.state.timerToSend != null){
            clearTimeout(this.state.timerToSend);
          }
          var timer = setTimeout(this.sendConfs,1000);
          this.setState({timerToSend:timer});
    }

    sendConfs = () =>{
        const customEvent = new CustomEvent(this.optionUpdateEvent, { detail: {
            logsOptions:this.state.logsOptions
        } 
        });
        document.dispatchEvent(customEvent);
    }

    render(){
        if(this.state.logsNames.length > 0){
            return(
                <>
                    <div style={{maxHeight:"480px", overflowX:"hidden", overflowY:"auto"}}>
                        {this.state.logsNames.map((logName:any, idx:any) => {
                            return (
                                <>
                                    <div className='row mt-3'>
                                        <div className='col-3'><b>{logName}</b></div>
                                        <div className='col-2'>
                                            <div className='fieldset'>
                                                <label style={{display:"grid"}} className='legend'> Log Weights
                                                    <input type='text' min={0.0} max={2.0} step={0.01} onChange={(event:any) => {
                                                        event.persist()
                                                        const caretStart = event.target.selectionStart;
                                                        const caretEnd = event.target.selectionEnd;
                                                        this.changeCurveWeights(event.target.value,logName, ()=>{event.target.setSelectionRange(caretStart, caretEnd);})
                                                        }} 
                                                        value={parseFloat(this.state.logsOptions[logName].logWeights).toFixed(2).toString()} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div className='fieldset'>
                                                <label style={{display:"grid"}} className='legend'> Norm
                                                    <input type='text' 
                                                        onChange={(event:any) => {
                                                            event.persist()
                                                            const caretStart = event.target.selectionStart;
                                                            const caretEnd = event.target.selectionEnd;
                                                            this.changeCurveNorms(event.target.value,logName, ()=>{event.target.setSelectionRange(caretStart, caretEnd);})
                                                            }
                                                        } 
                                                        value={parseFloat(this.state.logsOptions[logName].norm).toFixed(3).toString()} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='fieldset'>
                                                <label style={{display:"grid"}} className='legend'> Sampling method
                                                    <select onChange={(event:any) => {this.changeCurveSamplingMethod(event.target.value,logName)}} >
                                                        {samplingMethods.map((method,samplingIdx)=>{
                                                            if(this.state.logsOptions[logName].sampling == method.key){
                                                                return <option value={method.key} selected>{method.show}</option>
                                                            }
                                                            return <option value={method.key}>{method.show}</option>
                                                        })}
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </>
            )
        }
        else{
            return <>Select at least one Properties in <b>Properties tab</b> to display boundaries table</>
        }
    }

}

export default OptionsCorrelationsTab;