export default class WellInfoItem {
    name: string;
    unit: string;
    value: string;
    description: string;

    constructor(infoData: [string, string, string, string]) {
        this.name = infoData[0];
        this.unit = infoData[1];
        this.value = infoData[2];
        this.description = infoData[3];
    }
}