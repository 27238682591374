import shortid from "shortid";
import { ROW, COLUMN, COMPONENT } from "./constants";
import { ChartsEvents } from "../KogCharts/ChartsEvent";

export const setTrackHeadersUpdatedLayout = (components, layout, context) => {  
  let newItems = {}
  let currentIdx = 0
  let countTotalColumns = 0
  layout.forEach((row, rowIdx)=>{        
    const wellName = row.id
    newItems[row.id] = {}    
    row.children.forEach((column)=>{       
      currentIdx = countTotalColumns                        
      
      column.children.forEach((prop)=>{                     
        const propName = components[prop.id].content.props.children.props.children[0].props.children
        newItems[wellName][propName] = currentIdx     
      }) 

      countTotalColumns++     
    })    
  })  
  refreshTracks(newItems, context, layout)
}

export function refreshTracks(data, context, layout){
  // const customEvent = new CustomEvent(ChartsEvents.updateTrackConfigEvent, { detail: {
  //   data:data,
  //   context:context
  // } 
  // });
  // document.dispatchEvent(customEvent);
  let oQueVierPelaCabeca = { detail: {
      data:data,
      context:context
    } 
  }
  context.updateLayout(oQueVierPelaCabeca)
  // context.setHeaders(layout);
}


// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index

  return result;
};

export const remove = (arr, index) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // part of the array after the specified index
  ...arr.slice(index + 1)
];

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
];

export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  // Update the specific node's children
  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: reorderChildren(
      nodeChildren.children,
      splitDropZoneChildrenPath,
      splitItemChildrenPath
    )
  };

  return updatedChildren;
};

export const removeChildFromChildren = (children, splitItemPath) => {
  if (splitItemPath.length === 1) {
    const itemIndex = Number(splitItemPath[0]);
    return remove(children, itemIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitItemPath.slice(0, 1));

  // Update the specific node's children
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: removeChildFromChildren(
      nodeChildren.children,
      splitItemChildrenPath
    )
  };

  // console.log('Teste')

  // // ADDED REMOVE COLUMN AND ROW IF CHILDREN LENGTH = 0
  // updatedChildren.map((el, idx) =>{
  //   if (el.children.length == 0){      
  //     updatedChildren.splice(curIndex,1)
  //   }    
  // })

  return updatedChildren;
};

export const addChildToChildren = (children, splitDropZonePath, item) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    return insert(children, dropZoneIndex, item);
  }

  const updatedChildren = [...children];

  let curIndex = Number(splitDropZonePath.slice(0, 1));

  if (curIndex >= updatedChildren.length){
    curIndex = Number(updatedChildren.length) -1
  }

  // Update the specific node's children
  const splitItemChildrenPath = splitDropZonePath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: addChildToChildren(
      nodeChildren.children,
      splitItemChildrenPath,
      item
    )
  };

  return updatedChildren;
};

export const handleMoveWithinParent = (
  layout,
  splitDropZonePath,
  splitItemPath,
  components,
  context
) => {  
  const updatedLayout = reorderChildren(layout, splitDropZonePath, splitItemPath);
  setTrackHeadersUpdatedLayout(components, updatedLayout, context);
  return updatedLayout
};

export const handleAddColumDataToRow = layout => {
  const layoutCopy = [...layout];
  const COLUMN_STRUCTURE = {
    type: COLUMN,
    id: "column-" + shortid.generate(),
    children: []
  };

  return layoutCopy.map(row => {
    if (!row.children.length) {
      row.children = [COLUMN_STRUCTURE];
    }
    return row;
  });
};

export const handleMoveToDifferentParent = (
  layout,
  splitDropZonePath,
  splitItemPath,
  item,
  components,
  context
) => {
  let newLayoutStructure;
  const COLUMN_STRUCTURE = {
    type: COLUMN,
    id: "column-" + shortid.generate(),
    children: [item]
  };

  const ROW_STRUCTURE = {
    type: ROW,
    id: "row-" + shortid.generate(),
  };

  switch (splitDropZonePath.length) {
    case 1: {
      // moving column outside into new row made on the fly
      if (item.type === COLUMN) {
        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [item]
        };
      } else {
        // moving component outside into new row made on the fly
        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [COLUMN_STRUCTURE]
        };
      }
      break;
    }
    case 2: {
      // moving component outside into a row which creates column
      if (item.type === COMPONENT) {
        newLayoutStructure = COLUMN_STRUCTURE;
      } else {
        // moving column into existing row
        newLayoutStructure = item;
      }

      break;
    }
    default: {
      newLayoutStructure = item;
    }
  }

  let updatedLayout = layout;
  updatedLayout = removeChildFromChildren(updatedLayout, splitItemPath);
  updatedLayout = handleAddColumDataToRow(updatedLayout);
  updatedLayout = addChildToChildren(
    updatedLayout,
    splitDropZonePath,
    newLayoutStructure
  );


  // REMOVES EMPTY COLUMN AFTER MOVE PROPERTY TO ANOTHER COLUMN
  updatedLayout.forEach((row) => {
    row.children.forEach((column, idx) => {
      if(column.children.length <= 0){
        row.children.splice(idx, 1);
      }
    })
  })

  setTrackHeadersUpdatedLayout(components, updatedLayout, context);
  return updatedLayout;
};

export const handleRemoveItemFromLayout = (layout, splitItemPath) => {
  return removeChildFromChildren(layout, splitItemPath);
};
