/* eslint-disable */

import { RestApiService } from "../services/RestApiService";
import { Host_Login } from "../Config/Host";
import { setUserToken, setToken, setUserInfo } from '../utils/helpers/TokenHelper';

import '../utils/helpers/AxiosInterceptor';

export async function registerUser(params) {
  return new Promise((resolve, reject) => {
    var endpoint = `${Host_Login}/user/create`;
    RestApiService.post(endpoint, params)
    .then(response => {
      setToken(response.token);
      setUserInfo(
        JSON.stringify({
          id: response.id,
          name: response.username,
          email: params.login,
        })
      );
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });   
  });
}

export async function userLogin(params) {
  return new Promise((resolve, reject) => {
    var endpoint = `${Host_Login}/user/login`;
    RestApiService.post(endpoint, params)
    .then(response => {
      setToken(response.token);
      setUserInfo(
        JSON.stringify({
          id: response.id,
          name: response.username,
          email: params.login,
        })
      );
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });   
  });
}