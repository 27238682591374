/* eslint-disable */
export const Host = process.env.REACT_APP_BACKEND_URL
export const Host_Login = process.env.REACT_APP_AUTOLOG_LOGIN_APP_BACKEND_URL
export const Host_Well = process.env.REACT_APP_AUTOLOG_WELL_APP_BACKEND_URL
export const Host_Uploads = process.env.REACT_APP_AUTOLOG_UPLOAD_APP_BACKEND_URL
export const Host_Stratigraphic = process.env.REACT_APP_AUTOLOG_STRATIGRAPHIC_APP_BACKEND_URL
export const Host_Project = process.env.REACT_APP_AUTOLOG_PROJECT_APP_BACKEND_URL
export const Host_Correlation = process.env.REACT_APP_AUTOLOG_CORRELATIONS_BACKEND_URL
export const Host_Auth = process.env.REACT_APP_AUTH_URL

/*
var REACT_APP_BACKEND_URL='http://testing.kognitus.com.br/api';
var REACT_APP_AUTOLOG_PROJECT_APP_BACKEND_URL='http://testing.kognitus.com.br/api';
var REACT_APP_AUTOLOG_WELL_APP_BACKEND_URL='http://testing.kognitus.com.br/api';
var REACT_APP_AUTOLOG_UPLOAD_APP_BACKEND_URL='http://testing.kognitus.com.br/api';
var REACT_APP_AUTOLOG_LOGIN_APP_BACKEND_URL='http://testing.kognitus.com.br/api';
var REACT_APP_AUTOLOG_STRATIGRAPHIC_APP_BACKEND_URL='http://testing.kognitus.com.br/api'
var REACT_APP_AUTOLOG_CORRELATIONS_URL='http://testing.kognitus.com.br/api'
var REACT_APP_AUTH_URL='https://ocimachleehomolog.kognitus.com.br/nodeapi/';
var REACT_APP_GENERATE_SOURCEMAP=false;


export const Host = REACT_APP_BACKEND_URL
export const Host_Login = REACT_APP_AUTOLOG_LOGIN_APP_BACKEND_URL
export const Host_Well = REACT_APP_AUTOLOG_WELL_APP_BACKEND_URL
export const Host_Uploads = REACT_APP_AUTOLOG_UPLOAD_APP_BACKEND_URL
export const Host_Project= REACT_APP_AUTOLOG_PROJECT_APP_BACKEND_URL
export const Host_Stratigraphic= REACT_APP_AUTOLOG_STRATIGRAPHIC_APP_BACKEND_URL
export const Host_Correlation= REACT_APP_AUTOLOG_CORRELATIONS_URL
export const Host_Auth=process.env.REACT_APP_AUTH_URL
*/