import React, { Component } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import {
  FaChevronRight,
  FaChevronDown,
  FaCheckSquare,
  FaRegSquare,
} from "react-icons/fa";
import { EventNames } from '../../utils/enums/EventNames';
import StringHelper from '../../utils/helpers/StringHelper';
import WellLogInfoContainer from '../../utils/models/WellLogInfoContainer';

require('./checkbox-tree.css');

interface ActionsTabComponentProps {
  nodes: any;
  hasSearch: any;
  expandNodesToLevel: any;
}

interface ActionsTabComponentState {
  filterText: any;
  filteredNodes: any;
  expanded: any;
  _expandNodesToLevel: any;
  checked: any;
  hasSearch: any;
  timerToSend:any,
  dataset:any
}

export function refreshTreeComponent(wellNodes:any){
  const customEvent = new CustomEvent(EventNames.refreshTreeComponent, { detail: { wellNodes:wellNodes } });
  document.dispatchEvent(customEvent);
}

class CheckboxTreeComponent extends Component<ActionsTabComponentProps, ActionsTabComponentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      checked: [],
      filterText: '',
      filteredNodes: props.nodes,
      expanded: props.nodes, //this.expandNodesToLevel(props.nodes, 0),
      hasSearch: props.hasSearch,
      _expandNodesToLevel: props.expandNodesToLevel,
      timerToSend:null,
      dataset:{}
    };
  }

  setTimerToSendChanges = () => {
    if(this.state.timerToSend != null){
        clearTimeout(this.state.timerToSend);
      }
      var timer = setTimeout(this.sendConfs,1000);
      this.setState({timerToSend:timer});
  }

  expandNodesToLevel(nodes: any, targetLevel: any, currentLevel:any = 0) {
    if (currentLevel > targetLevel) {
        return [];
    }

    let expanded:any = [];
    nodes.forEach((node:any) => {
        if (node.children) {
            expanded = [
                ...expanded,
                node.value,
                ...this.expandNodesToLevel(node.children, targetLevel, currentLevel + 1),
            ];
        }
    });
    return expanded;
}

  componentDidMount() {
    document.addEventListener(EventNames.uploadLasEvent, this.handleUploadLasEvent);
    document.addEventListener(EventNames.hideNodesEvent, this.hideWellNodesEvent);
    document.addEventListener(EventNames.hideNodesEvent, this.handleHideNodesEvent as EventListener);
    document.addEventListener(EventNames.refreshTreeComponent, this.refreshWellNodes as EventListener);
  }

  componentWillUnmount() {
    document.removeEventListener(EventNames.uploadLasEvent, this.handleUploadLasEvent);
    document.removeEventListener(EventNames.hideNodesEvent, this.hideWellNodesEvent);
    document.removeEventListener(EventNames.hideNodesEvent, this.handleHideNodesEvent as EventListener);
    document.removeEventListener(EventNames.refreshTreeComponent, this.refreshWellNodes as EventListener);
  }

  refreshWellNodes = (event:any) =>{
    if (event.type !== EventNames.refreshTreeComponent){
      return;
    }
    const dataset = event.detail.wellNodes;
    this.setState({
      filteredNodes: dataset,
      expanded: dataset, //this.expandNodesToLevel(props.nodes, 0),
    })
  }

  handleHideNodesEvent = (event: CustomEvent) => {
    if (event.type !== EventNames.hideNodesEvent){
      return;
    }
    var wellNodes = this.state.filteredNodes;
    var _wellNodes = [];
    if(event.detail.log){     
      for(var i=0;i<wellNodes.length;i++){
        var node = wellNodes[i];
        var obj = StringHelper.parseIdStringToObject(event.detail.well.id);
        if(node.value.label != obj.well.label){
          _wellNodes.push(node);
        }else{
          console.log('nha');
          var _children = [];
          for(var j=0;j<node.children.length;j++){
            var child = node.children[j];
            var obj2 = StringHelper.parseIdStringToObject(child.value.id);
            if(obj2.log.label != event.detail.log.label){
              _children.push(child)
            }            
          }
          node.children = _children;
          _wellNodes.push(node);
        }
      }
    } else if(event.detail.well){
      wellNodes = wellNodes.filter((node:any) => {
        return node.value.label != event.detail.well.label;
      });
    }
    this.setState({filteredNodes: wellNodes})
  };

  handleUploadLasEvent = (event: any) => {
    // Handle uploadLasEvent logic here
  };

  hideWellNodesEvent = (event: any) => {
    // Handle hideNodesEvent logic here
  };

  handleCheck = (checkedElementAStringArray: any, targetNode: any) => {
    this.setState({ checked: checkedElementAStringArray});

    var checkedItemsArray = [];
    for(var i=0;i<checkedElementAStringArray.length;i++){
      var decoded = StringHelper.parseIdStringToObject(checkedElementAStringArray[i]);
      checkedItemsArray.push(decoded);
    }

    var params = this.getParams(checkedItemsArray);
    this.setState({dataset:{checkedItemsArray, checkedElementAStringArray, LogParams: params}},()=>{
      this.setTimerToSendChanges()
    })
  };
  sendConfs = () =>{
    const customEvent = new CustomEvent(EventNames.checkedTreeViewEvent, { detail: this.state.dataset });
    document.dispatchEvent(customEvent);
  }

  handleExpand = (expanded: any) => {
    this.setState({ expanded });
  };

  onFilterChange = (e: any) => {
    const value = e.target.value;
    this.setState({ filterText: value }, this.filterTree);
  };

  filterTree = () => {
    const { filterText } = this.state;
    let filteredNodes = this.props.nodes;

    if (filterText) {
      filteredNodes = this.props.nodes.reduce(this.filterNodes, []);
    }

    this.setState({ filteredNodes });
  };

  filterNodes = (filtered: any, node: any) => {
    const { filterText } = this.state;
    const children = (node.children || []).reduce(this.filterNodes, []);

    const foundIdx = node.label.props.children.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase());
    if (foundIdx > -1 || children.length) {
      filtered.push({ ...node, children });
    }

    return filtered;
  };

  getParams(checkedItemsArray: any){
    var groupedItems: any = {};    

    checkedItemsArray.forEach((item: any) => {
      const wellLabel = item.well.label;
      if (!groupedItems[wellLabel]) {
        groupedItems[wellLabel] = [];
      }
      groupedItems[wellLabel].push(item);
    });

    var params:any[] = [];
    for (const [wellLabel, items] of Object.entries(groupedItems) as [string, any][]) {
      var logNameList:any = [];
      var wellname: string = wellLabel;
      var versionId: any = null;
      items.forEach((item:any, index:any) => {
        if(index==0){
          versionId = Number(item.log.version_id);
        }
        logNameList.push(item.log.label);

      });
      var wellLogInfoContainer = new WellLogInfoContainer(wellname, versionId, logNameList);
      params.push(wellLogInfoContainer);
    }
    return params;
  }

  setCheck = () => {
    if(this.state.filteredNodes.length > 0){
      return <FaCheckSquare className="rct-icon rct-icon-check" fillOpacity='0.9' fill={"#f50057"} size={'28px'}/>
    }
    return <></>
  }

  setUncheck = () => {
    if(this.state.filteredNodes.length > 0){
      return <FaRegSquare className="rct-icon rct-icon-uncheck" size={'28px'} />
    }
    return <></>
  }


  render() {
    const { checked, filterText, filteredNodes, expanded, hasSearch, _expandNodesToLevel } = this.state;

    return (
      <div className="filter-container">
        {hasSearch && (
          <input
            className="filter-text"
            placeholder="Search..."
            type="text"
            value={filterText}
            onChange={this.onFilterChange}
          />
        )}
        <CheckboxTree
          id='CheckboxTree'
          showExpandAll={false}
          nodes={this.state.filteredNodes}
          checked={checked}
          expanded={expanded}
          onCheck={this.handleCheck}
          onExpand={this.handleExpand}
          icons={{
            check: this.setCheck(),
            uncheck: this.setUncheck(),
            halfCheck: <FaCheckSquare className="rct-icon rct-icon-check" fillOpacity='0.9' fill={"#f50057"} size={'28px'}/>,
            expandClose: <FaChevronRight className="rct-icon rct-icon-expand-close" />,
            expandOpen: <FaChevronDown className="rct-icon rct-icon-expand-open" />,
            expandAll: <FaChevronDown className="rct-icon rct-icon-expand-all" />,
            collapseAll: <FaChevronRight className="rct-icon rct-icon-collapse-all" />,
            parentClose: <></>,
            parentOpen: <></>,
            leaf: <></>,
          }}
        />
      </div>
    );
  }
}

export default CheckboxTreeComponent;
