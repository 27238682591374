/**
 * The LithoCatalog class represents a color catalog for lithology identifiers.
 *
 * The identification of each lithology is made from an integer and the color associated with the identifier is in hexadecimal format.
 */

export default class LithoCatalog {
    public mapping: Map<number, string>;

    /**
     * Constructor where some lithology identifiers are mapping to hexadecimal colors.
     */
    constructor() {
        this.mapping = new Map();
        this.mapping.set(8, '#FFDB28');
        this.mapping.set(6, '#3A89FF');
        this.mapping.set(57, '#A811FF');
        this.mapping.set(49, '#3FFF52');
        this.mapping.set(42, '#FF9D68');
    }

    /**
     * @returns A mapping with litology identifiers and hexadecimal colors.
     */
    colors():any {
        return this.mapping;
    }
}
