import React, { Component } from 'react';
import ToastHelper from '../../../utils/helpers/ToastHelper';
import {OneSpace, DoubleQuotation, Hash, Meter, MD, m9999_99} from '../../ParserFields/ParserValues';
import FixWellInfosTable, { updateTable } from '../../FixWellInfosTable/FixWellInfosTable';
import { StandartColors } from '../../../utils/enums/StandartColors';
import { EventNames } from '../../../utils/enums/EventNames';
import { uploadWellDeviation } from '../../../services/wellService';
import AttributeParameters, { MD as MD_header, TVD, x, y, z, DX, DY, AZIM_TN, INCL, DLS, AZIM_GN} from '../../PreviewTable/TableOptions';
import LoadFileComponent from '../../LoadFileComponent/LoadFileComponent';
import SelectDataComponent from '../../SelectDataComponent/SelectDataComponent';

interface WellPathsComponentState {
  fileList: any[];
  activeTab: string;
  dataList: any[];
  startLine: number;
  newWellsNames: any;
  fileData: any[]
  headersColumns: any;
  delimiter: string;
  qualifier: string;
  comment: string;
  depthUnit: string;
  depthType: string;
  noValue: string;  
  wellNames: any;  
}

interface WellPathsComponentProps {
  
}

enum TabNames{
  uploadFile = "Load File",
  selectData = "Select Data",
  correctWellInfo = "Correct Well Info"
}

const tabOrder: string[] =[
  TabNames.uploadFile,
  TabNames.selectData,
  TabNames.correctWellInfo,
]

const CONTEXT = 'wellPaths';

export default class WellPathsComponent extends Component<WellPathsComponentProps, WellPathsComponentState> {
  addFiles: string = EventNames.CsvUploaderComponentUpdateListEvent;
  updatedParserEvent: string = EventNames.updatedParserEvent;
  changeWellsNamesEvent = EventNames.updatedWellNamesEvent;
  previewTableChangedEvent: string = EventNames.previewTableChangedEvent;
  loadFileComponentEvent: string = EventNames.LoadFileComponentEvent;
  SelectDataComponentEvent: string = EventNames.SelectDataComponentEvent;  
  SelectDataComponentClickUploadButton: string = EventNames.SelectDataComponentClickUploadButton;  
  SelectDataComponentParserInfoEvent: string = EventNames.SelectDataComponentParserInfoEvent; 

    constructor(props: any) {
      super(props);
      this.state = {
        activeTab: TabNames.uploadFile,
        wellNames:[],
        fileList: [],
        dataList:[],
        startLine: 1,
        newWellsNames:{},
        fileData:[],       
        delimiter:OneSpace.value,
        qualifier:DoubleQuotation.value,
        comment:Hash.value,
        depthUnit:Meter.value,
        depthType:MD.value,
        noValue:m9999_99.value,
        headersColumns:{                    
          mdColumn:1,
          x: 2,
          y: 3,
          z: 4,
          TVD: 5,  
          DX: 6, 
          DY: 7, 
          AZIM_TN: 8, 
          INCL: 9, 
          DLS: 10, 
          AZIM_GN: 11
        },
      };
    }

    componentDidMount() {  
      document.addEventListener(this.loadFileComponentEvent, this.setFiles);
      document.addEventListener(this.SelectDataComponentEvent, this.receiveDataset);
      document.addEventListener(this.SelectDataComponentClickUploadButton, this.receiveClickUploadButton);
      document.addEventListener(this.SelectDataComponentParserInfoEvent, this.receiveParserInfo);
      document.addEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
    }
  
    componentWillUnmount() {
      document.removeEventListener(this.loadFileComponentEvent, this.setFiles);
      document.removeEventListener(this.SelectDataComponentEvent, this.receiveDataset);
      document.removeEventListener(this.SelectDataComponentClickUploadButton, this.receiveClickUploadButton);
      document.removeEventListener(this.SelectDataComponentParserInfoEvent, this.receiveParserInfo);
      document.removeEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
    }

    fixWellsNames =(event:any) => {
      if (event.type !== this.changeWellsNamesEvent && event.detail.context != CONTEXT){
        return;
      }
      let dataset = event.detail;
      let prevState = this.state.newWellsNames;
      prevState = this.processNewWellsNames(dataset.wellInfosContents);
      this.setState({newWellsNames:prevState});
    }
  
    processNewWellsNames (dataset:any){
      let newWellsNames: any[] = []
      dataset.forEach((row:string[],rowIdx:number)=>{
        // if(!Object.keys(newWellsNames).includes(row[0])){
        //   newWellsNames[row[0]] = {};
        // }
        // if(!newWellsNames[row[0]][row[1]]){
        //   newWellsNames[row[0]]["oldName"] = row[1];
        //   newWellsNames[row[0]]["newName"] = row[2];
        // }
        newWellsNames.push({"oldName": row[1], "newName": row[2]});
      })
      return newWellsNames;
    }
  

    setFiles = (event:any) => {
      if (event.type !== this.loadFileComponentEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;      
      let wells_infos = dataset.fileList.map((file:any,fileIdx:number)=>{
        return [file.name, file.name.split(".")[0], file.name.split(".")[0]]
      })
      updateTable(wells_infos,CONTEXT);      
      this.setState({fileList:dataset.fileList, dataList: wells_infos, newWellsNames: wells_infos},()=>{});
    }

    receiveDataset = (event:any) => {
      if (event.type !== this.SelectDataComponentEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;
      this.setState({dataList: dataset.dataList, headersColumns:dataset.headersColumns})
      updateTable(dataset.dataList, CONTEXT);
    }
  
    receiveClickUploadButton = (event:any) => {
      if (event.type !== this.SelectDataComponentEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;
      //this.setState({dataList: dataset.dataList})
      //updateTable(dataset.dataList, CONTEXT);
      this.upload();
    }
  
    receiveParserInfo = (event:any) => {
      if (event.type !== this.SelectDataComponentParserInfoEvent && event.detail.context != CONTEXT){
        return;
      }
  
      const dataset = event.detail;
      this.setState({
        //dataList: dataset.dataList, 
        //newWellsNames: dataset.newWellsNames, 
        fileData: dataset.fileData,        
        delimiter:dataset.delimiter,
        qualifier:dataset.qualifier,
        comment:dataset.comment,
        startLine:dataset.startLine,
        depthUnit:dataset.depthUnit,
        depthType:dataset.depthType,
        noValue:dataset.noValue,
      })            
    }
    
    createDefaultColumns = () =>{
      let defaultColumns:any = {};     
      
      defaultColumns[this.state.headersColumns.mdColumn] = MD_header.value
      defaultColumns[this.state.headersColumns.x] = x.value,
      defaultColumns[this.state.headersColumns.y] = y.value,
      defaultColumns[this.state.headersColumns.z] = z.value,
      defaultColumns[this.state.headersColumns.TVD] = TVD.value,
      defaultColumns[this.state.headersColumns.DX] = DX.value,
      defaultColumns[this.state.headersColumns.DY] = DY.value,
      defaultColumns[this.state.headersColumns.AZIM_TN] = AZIM_TN.value,
      defaultColumns[this.state.headersColumns.INCL] = INCL.value,
      defaultColumns[this.state.headersColumns.DLS] = DLS.value,
      defaultColumns[this.state.headersColumns.AZIM_GN] = AZIM_GN.value
      
      return defaultColumns
    }    

    setTab = (tabName:string) => {
      this.setState({activeTab:tabName});
    }

    tabHeader = () =>{
      return (
        <div className="process-nav">{tabOrder.map((currentTab,idx)=>{
          return (
            <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
              {currentTab}
            </div>
          )
        })}
        </div>
      )
    }

    /**
   * Upload data
   */
    upload = () =>{

      if(this.state.fileList.length == 0){
        ToastHelper.warning("There is no information to load, please add files in 'Load File' tab and try again.");
        return;
      }

      // const wells_names = this.state.dataList.map((row,rowIdx)=>{
      //   return row[1];
      // });

      // let new_well_names:any[] = [];

      // Object.keys(this.state.newWellsNames).forEach((filename:string,idx:number)=>{
      //   new_well_names.push({oldName:this.state.newWellsNames[idx][1],newName:this.state.newWellsNames[idx][2]});
      // });

      const new_well_names = this.processNewWellsNames(this.state.dataList);
    
      var data = new FormData();
      for(var idx = 0; idx<this.state.fileList.length; idx++){
        data.append("file", this.state.fileList[idx]);
      }

      let headerDataset = "";

      if(this.state.fileData.length == 0){
        ToastHelper.warning("Could not parse file(s) correctly, please check parameters in 'Select Data' tab and try again.");
        return;
      }

      this.state.fileData[0].forEach((data:any,idx:any)=>{  
        if(idx < this.state.fileData[0].length){
          const headersColumns = Object.keys(this.state.headersColumns)
        
          const headerField = headersColumns.filter(value => this.state.headersColumns[value] === idx + 1);
          if(headerField.length > 0 && idx > 0){
            headerDataset = headerDataset + this.state.delimiter;
          }
          if(headerField.length > 0){
            let fieldName = headersColumns[idx]
            if(fieldName == "mdColumn"){
              fieldName = "MD"
            }
            headerDataset = headerDataset + fieldName.toUpperCase();
          }
        }
      })

      let dataset = {
        wellnames: new_well_names,      
        delimiter: this.state.delimiter,
        qualifier: this.state.qualifier,
        start_line: this.state.startLine,
        comment: this.state.comment,
        header: true,
        header_data: headerDataset,
      }
      data.append("data",JSON.stringify(dataset))
      uploadWellDeviation(data).then((response)=>{
        if(response.response.errors.length > 0){
          var files_error = response.response.errors.join(", ");
          ToastHelper.error("Error when upload well(s) path(s) on file(s): " + files_error, ()=>{
            console.log(files_error);
          });
        }
        if (response.response.success.length > 0){
          var files_success = response.response.success.join(", ");
          ToastHelper.success("Upload well(s) path(s) was successful! " + files_success);
        }
      })
    }

    tabsView = () =>{
      let sectionStyle = {
        marginTop: '0px'
      };
  
      let navItemStyle = {
        backgroundColor: "white",
        color: StandartColors.DarkBlack,
        //padding: '0 15px 0 15px'
      };
  
      return (
        <section id="tabs" style={sectionStyle}>
          <div className="row mb-3">            
            {this.tabHeader()}
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="tab-content" id="nav-tab-map-view-component" style={{backgroundColor:"white"}}>
                <div
                  className={`tab-pane fade show ${this.state.activeTab === TabNames.uploadFile ? 'active' : ''}`}
                  id="Map-View"
                  role="tabpanel"
                  style={navItemStyle}
                >
                  <LoadFileComponent context={CONTEXT}/>
                </div>
                <div
                  className={`tab-pane fade show ${this.state.activeTab === TabNames.selectData ? 'active' : ''}`}
                  id="Map-View"
                  role="tabpanel"
                  style={navItemStyle}
                >
                  <SelectDataComponent context={CONTEXT} 
                    headersColumns={this.state.headersColumns} 
                    defaultColumns={this.createDefaultColumns()} 
                    delimiter={this.state.delimiter} 
                    qualifier={this.state.qualifier} 
                    comment={this.state.comment}
                    depthUnit={this.state.depthUnit}
                    depthType={this.state.depthType}
                    noValue={this.state.noValue}
                    listHeaderOptions={new AttributeParameters().deviation}
                  />
                </div>              
                <div
                  className={`tab-pane fade show ${this.state.activeTab === TabNames.correctWellInfo ? 'active' : ''}`}
                  id="Map-View"
                  role="tabpanel"
                  style={navItemStyle}
                >
                  <div className='row'>
                    <div className='col'>
                      <b>Correct information read in file:</b>
                    </div>
                    <div className='col-2 mb-3'>
                      <input type="button" className="btn btn-dark" value="Load" onClick={()=>{this.upload()}} />
                    </div>
                  </div>
                  <FixWellInfosTable wellsData={this.state.dataList} context={CONTEXT} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )
  
    }
  
    render(){
      return(
        <>
          {this.tabsView()}
        </>
      )
    }
  }