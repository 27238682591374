import LithoCatalog  from '../../components/Incharts/Original/well/LithoCatalog';
import GlobalWellMetadataInfo from "../../global/GlobalWellMetadataInfo";
import { StandartColors } from "../enums/StandartColors";


var getTrack = (curveNames: any, logType:any, wellViewConfs:any, colorConfs:any) :any => {
    return{
        "type": logType,
        // "background-color": wellViewConfs.trackFontSize,
        "font-size": parseInt(wellViewConfs.trackFontSize),
        "font-family": wellViewConfs.trackFontName,
        "font-color": StandartColors.DarkGray,
        "grid-row-color": StandartColors.OtherLightGray,
        "grid-cell-size": parseInt(wellViewConfs.trackLinesThickness),
        "curves": curveNames.map((curveName:any,idx:any)=>{
        
            var currentColors = colorConfs[curveName]?colorConfs[curveName]:{line:StandartColors.DarkRed, fill:StandartColors.Orange};
              return {
                "name": curveName,
                "wrap": true,
                "interpolation": "Linear",
                "line-color": currentColors.line,
                "line-width": 2,
                "left-fill": {
                    "reference-line": "LeftBorder",
                    "baseline-value": null,
                    "fill-color": currentColors.fill
                },
                "right-fill": {
                    "reference-line": "LeftBorder",
                    "baseline-value": null,
                    "fill-color": currentColors.fill
                }
            }
            })           
    }
}

class InpetuTrackPlotMetadata{
    private metadata: {[index: string]:any};
    private depthType: string;
    private wellViewConfs: any;
    private colorConfs: any;
    private curveData: any;

    constructor(){
        this.metadata = {};
        this.depthType = "MD";
        this.wellViewConfs = {
            trackFontSize:StandartColors.Orange,
            trackFontName:"Arial",
            trackLinesThickness:2,
            trackWidth:200,
            trackgap:20,
            trackborderColor:StandartColors.LightGray,
            trackheadersborderColor:StandartColors.LightGray
        };
        this.colorConfs = {};
        this.curveData = {}
    }

    public setCurveData(curveData:any){
        this.curveData = curveData;
    }

    public setDepthType(depthTypeName:string){
        this.depthType = depthTypeName
    }

    public getDepthType(){
        return this.depthType
    }

    public setColorConfs(colorConfs:any){
        this.colorConfs = colorConfs;
    }

    /**
     * Add a track in well.
     * @param wellName Well name to add a track.
     */
    public addTrack(wellName:any){
        if(!this.metadata[wellName]){
            this.metadata[wellName] = {
                wellname:wellName, plots:[]
            }
        }
        this.metadata[wellName].plots.push([]);
    }

    /**
     * Remove a well track.
     * @param wellName Well name.
     * @param trackIdx Track index.
     */
    public removeTrack(wellName:any, trackIdx:number){
        if(this.metadata[wellName]){
            this.metadata[wellName].plots.pop(trackIdx,1);
        }
    }

    /**
     * Remove last track.
     * @param wellName Well name.
     */
    public removeLastTrack(wellName:any){
        if(this.metadata[wellName]){
            this.removeTrack(wellName, this.metadata[wellName].plots.length -1)
        }
    }

    /**
     * Add curves in trackplot.
     * @param wellName WellName.
     * @param trackIdx Track index.
     * @param curves List with curve names.
     */
    public addTrackCurve(wellName:any, trackIdx:number, curves:string[]){
        if(this.metadata[wellName] && this.metadata[wellName].plots[trackIdx]){
            this.metadata[wellName].plots[trackIdx] = curves;
        }
    }

    /**
     * Clear Tracks metadata.
     */
    public clear(){
        this.metadata = {}
    }

    /**
     * Check if metadata is empty.
     * @returns boolean.
     */
    public isEmpty():boolean {
        var isEmptyObject = true;
        if(Object.keys(this.metadata).length > 0){
            Object.keys(this.metadata).forEach((wellName:any,idx:any)=>{
                this.metadata[wellName].plots.forEach((plotTrack:any)=>{
                    if(plotTrack.length>0){
                        isEmptyObject = false
                    }
                })
            })
        }
        return isEmptyObject
    }

    public getDataset(){
        var dataset:any[] = [];
        if(!this.isEmpty()){
            Object.keys(this.metadata).forEach((wellName:any,idx:any)=>{
                var currentDataset = {
                    title: wellName,
                    tracks:[
                        getTrack(
                            [this.depthType],
                            "DEPTH",
                            this.wellViewConfs,
                            this.colorConfs
                            )
                    ]
                }
                this.metadata[wellName].plots.forEach((plotConf:any,plotIdx:any)=>{
                    currentDataset.tracks.push(
                        getTrack(
                            plotConf,
                            "log",
                            this.wellViewConfs,
                            this.colorConfs
                            )
                    )
                })
                dataset.push(currentDataset);
            })
        }
        else{
            Object.keys(this.curveData).forEach((wellName:any,idx:any)=>{
                var currentDataset = {
                    title: wellName,
                    tracks:[
                        getTrack(
                            [this.depthType],
                            "DEPTH",
                            this.wellViewConfs,
                            this.colorConfs
                            )
                    ]
                }
                this.curveData[wellName].forEach((plotConf:any,plotIdx:any)=>{
                    currentDataset.tracks.push(
                        getTrack(
                            [plotConf],
                            "log",
                            this.wellViewConfs,
                            this.colorConfs
                            )
                    )
                })
                dataset.push(currentDataset);
            })
        }
        return dataset;
    }

    public totalTracks(){
        var tracks = 0;
        if(!this.isEmpty()){
            Object.keys(this.metadata).forEach((wellName:any,idx:any)=>{
                this.metadata[wellName].plots.forEach((plotConf:any,plotIdx:any)=>{
                    tracks += plotConf.length;
                });
            });
        }
        else{
            Object.keys(this.curveData).forEach((wellName,idx)=>{
                tracks += this.curveData[wellName].length;
            })
        }
        return tracks;
    }

    public getMetadata(){
        var temp_metadata:any = [];
        Object.keys(this.metadata).forEach((wellName:any,idx:any)=>{
            temp_metadata.push( this.metadata[wellName] );
        })
        return temp_metadata;
    }

    public getWellNames(){
        return Array( Object.keys( this.metadata ) );
    }

    public setWellViewConfs(confData:any){
        this.wellViewConfs = confData;
    }

    public getWellViewConfs(){
        return this.wellViewConfs;
    }

}

class WellCurveData {
    private data: {[index: string]:any};

    constructor(){
        this.data = {};
    }

    /**
     * Add a curve in well.
     * @param wellName String with well name.
     * @param curveData List with curve data.
     * @param curveName String with curve name.
     * @param curveUnit String with curve unit.
     */
    public addWellCurve(wellName:any, curveData:any[], curveName:any, curveUnit:String, curveDescription:String){
        var curveMin = Math.min.apply(
            null,
            curveData
        )
        var curveMax = Math.max.apply(
            null,
            curveData
        )
        if(!this.data[wellName]){
            this.data[wellName] = {}
        }

        this.data[wellName][curveName] = {
            name:curveName, values:curveData, unit:curveUnit, description:curveDescription, limits:{ min:curveMin, max:curveMax }
        };
    }

    /**
     * Remove a well cruve.
     * @param wellName String with well name.
     * @param curveName String with curve name.
     */
    public removeWellCurve(wellName:any,curveName:any){
        delete this.data[wellName][curveName];
    }

    /**
     * Clear all data.
     */
    public clearAllData(){
        this.data = {}
    }

    /**
     * Clear data in well.
     * @param wellName String with well name
     */
    public clearWellData(wellName:any){
        delete this.data[wellName];
    }

    /**
     * Get curve names in well.
     * @param wellName String with well name.
     * @returns List with curve names in well.
     */
    public getCurvesNames(wellName:any){
        return Object.keys(this.data[wellName]);
    }

    /**
     * Get all wells names.
     * @returns List with well names.
     */
    public getWellName(){
        return Object.keys(this.data);
    }

    /**
     * Get inpetu dataset.
     * @returns Inpetus's Multiwell Widget dataset.
     */
    public getDataset(){
        var dataset: any[] = [];
        this.getWellName().forEach((wellName,value)=>{
            dataset.push(this.data[wellName]);
        });
        return dataset;
    }
}

export default class InpetuHelper {

    data: WellCurveData;
    metadata: InpetuTrackPlotMetadata;
    
    constructor(){
        this.data = new WellCurveData();
        this.metadata = new InpetuTrackPlotMetadata();
    }

    /**
     * Add well data.
     * @param logs_data Well dataset.
     */
    public addWellsData(logs_data:any[]){
        var depthsCurve = ["MD", "TVD", "TVDSS"]
        var curveData: {[index: string]:any} = {}
        logs_data.forEach((well:any,key:any)=>{
            var wellName = well.well;
            curveData[wellName] = well.curves.map((curve:any,key:any)=>{
                return curve.name
            })
            depthsCurve.forEach((depthName,idx)=>{
                if(curveData[wellName].includes(depthName)){
                    curveData[wellName].splice(curveData[wellName].indexOf(depthName),1);
                }
            })
            well.curves.forEach((curve:any,key:any)=>{
                this.data.addWellCurve(
                    wellName,
                    curve.values,
                    curve.name,
                    this.findCurveUnit(well.well_version_id, curve.name, wellName),
                    ""
                    )
            });
        })
        this.data.getWellName().forEach((wellName,idx)=>{
            if(!this.metadata.getWellNames().includes([wellName])){
                this.metadata.addTrack(wellName);
            }
        })
        this.metadata.setCurveData(curveData);
    }

    /**
     * Define depth type, can be 'MD', 'TVD' or 'TVDSS'.
     * @param depthType String with depth type.
     */
    public setDepthType(depthType:string){
        this.metadata.setDepthType(depthType)
    }

    public addWellTrack(wellName:any){
        this.metadata.addTrack(wellName);
    }

    public dropWellTrack(wellName:any){
        this.metadata.removeLastTrack(wellName);
    }

    public addTrackCurve(wellName:any, trackIdx:number, curves:string[]){
        this.metadata.addTrackCurve(wellName, trackIdx, curves);
    }

    public getMetadata(){
        return this.metadata.getMetadata()
    }

    public getWellCurves(wellName:any){
        return this.data.getCurvesNames(wellName);
    }



    /**
     * Find well curve info.
     * @param versionId Well version.
     * @param logName Log name.
     * @param wellName Well name.
     * @returns Curve unit.
     */
    private findCurveUnit(versionId: number, logName: string , wellName: string): any{
        // TODO: Improve this function
        var wellMetadataInfo = GlobalWellMetadataInfo.getDataFromInstance();

        for(var i=0;i< wellMetadataInfo.wellMetadataList.length;i++){
            var item = wellMetadataInfo.wellMetadataList;
            for(var j=0;j<item.length;j++){
                var versionMetadata = item[j].versionMetadata[0];
                
                if(versionMetadata.id === versionId){
                    for(var k=0;k<versionMetadata.curveMetatdataInfoList.length;k++){
                        var curve = versionMetadata.curveMetatdataInfoList[k];
                        if(curve.name === logName){
                            return curve.unit;
                        }
                    }
                }
            }
        }    

        return '';
    }

    public getLabelX() {
        return this.metadata.getDepthType()
    }

    public setDisplayConfs(WellviewConfs:any){
        this.metadata.setColorConfs(WellviewConfs.wellTracksColor)
        this.metadata.setWellViewConfs(WellviewConfs.wellViewState)
    }

    public getInpetuFormat(){
        var tracksConf = this.metadata.getWellViewConfs();
        return {
            // "trackLayoutMetadataContainer": this.getTracksLayoutMetadataContainer(title,this.inpetuModel.xSerie,YNameSerie),
            "trackLayoutMetadataContainer": this.metadata.getDataset(),
            "curves": this.data.getDataset(),
            "wells":this.data.getWellName(),
            "tops": [],
            "trackWidth": parseInt(tracksConf.trackWidth),
            "leftMargin": 0,
            "spaceBetweenTracks": parseInt(tracksConf.trackgap),
            "width": (parseInt(tracksConf.trackWidth)*(this.metadata.totalTracks()+2)) * this.data.getWellName().length,
            "height": 750,
            "gridBorderColor": tracksConf.trackborderColor,
            "headerOptions": {
                "height": 80,
                "top": 0,
                "borderColor": tracksConf.trackheadersborderColor,
                "color": "#000000",
                "padding": [
                    0,
                    0,
                    0,
                    0
                ],
                "textBlockHeight": 15,
                "horizontalLineHeight": 2,
                "spaceBetweenTitleItems": 20,
                "trackTitleHeight": 50
            },
            "litoCatalog": {
                "mapping": new LithoCatalog()
            }
        }
    }
}