import React, { useState, useCallback } from "react";

import DropZone from "./DropZone";
import Row from "./Row";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,    
} from "./helpers";

import { COLUMN, ROW } from "./constants";

const Container = (initialData) => {
  const initialLayout = initialData.data.layout;
  const initialComponents = initialData.data.components;
  const context = initialData.context;
  const [layout, setLayout] = useState(initialLayout);
  const plotConfig = initialData.plotConfig;
  const [components, setComponents] = useState(initialComponents);

  const handleDrop = useCallback(
    (dropZone, item) => {
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      const newItem = { id: item.id, type: item.type };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }     

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {          
          setLayout(
            handleMoveWithinParent(layout, splitDropZonePath, splitItemPath, components, context)
          );          
          return;
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children        
        setLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem,
            components,
            context,          
          )
        );        
        return;
      }

      // 3. Move + Create      
      setLayout(
        handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,  
          newItem,
          components,
          context,   
        )
      );      
    },    
    [layout, components]
  );

  const renderRow = (row, currentPath) => {   

    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        plotConfig={plotConfig}
      />
    );
  };

  // dont use index for key when mapping over items
  // causes this issue - https://github.com/react-dnd/react-dnd/issues/342
  return (
    <>
      {layout?.map((row, index) => {
        const currentPath = `${index}`;

        return (
          <React.Fragment key={row.id}>
            <DropZone
              className={"verticalDrag externalDrag"}
              data={{
                path: currentPath,
                childrenCount: layout.length
              }}
              onDrop={handleDrop}
              path={currentPath}
              accepts={ROW}
            />            
            {renderRow(row, currentPath)}
          </React.Fragment>
        );
      })}
      <DropZone
        className={"verticalDrag externalDrag"}
        data={{
          path: `${layout.length}`,
          childrenCount: layout.length
        }}
        onDrop={handleDrop}
        accepts={ROW}
        isLast
      />      
    </>
  );
};
export default Container;
