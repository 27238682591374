import React, { Component } from 'react';
import SignIn from '../../components/Login/SignIn';

interface LoginPageState {
  
}

class LoginPage extends Component<{}, LoginPageState> {
  constructor(props: any) {
    super(props);
  }  

  render() {

    return (
      <div style={{marginTop: '-30vh', marginLeft:'20vw',  marginRight:'20vw'}}>
        <SignIn />
      </div>      
    );
  }
}

export default LoginPage;