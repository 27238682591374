import React, { Component } from 'react';
import ToastHelper from '../../../utils/helpers/ToastHelper';
import { EventNames } from '../../../utils/enums/EventNames';
import { uploadWellLogs, wellMetadata } from '../../../services/wellService';
import { PulseLoader } from "react-spinners";
import FixWellInfosTable, { updateTable } from '../../FixWellInfosTable/FixWellInfosTable';
import { StandartColors } from '../../../utils/enums/StandartColors';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';
import LoadFileComponent from '../../LoadFileComponent/LoadFileComponent';

interface WellLogsProps{
  fileList: any[];
}

interface WellLogsComponentState {
    fileList: any[];
    uploading: boolean;
    newWellNames:any;
    activeTab:string;
    newWellsNames: any;
    dataList: any[];
}

const CONTEXT = 'wellLogs';

enum TabNames{
  uploadFile = "Load File",
  selectData = "Select Data",
  correctWellInfo = "Correct Well Info"
}

const tabOrder: string[] =[
  TabNames.uploadFile,
  TabNames.selectData,
  TabNames.correctWellInfo,
]

export default class WellLogsComponent extends Component<{}, WellLogsComponentState> {
  addFiles: string = EventNames.CsvUploaderComponentUpdateListEvent;
  changeWellsNamesEvent = EventNames.updatedWellNamesEvent;
  loadFileComponentEvent: string = EventNames.LoadFileComponentEvent;

  constructor(props: any) {
      super(props);
      this.state = {
        activeTab: TabNames.uploadFile,
        fileList: [],
        uploading: false,
        newWellNames:[],
        dataList:[],
        newWellsNames:{},
      };
  }

  componentDidMount() {  
    document.addEventListener(this.loadFileComponentEvent, this.setFiles);
    document.addEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
  }
  
  componentWillUnmount() {
    document.removeEventListener(this.loadFileComponentEvent, this.setFiles);
    document.removeEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
  }

  // fixWellsNames =(event:any) => {
  //   if (event.type !== this.changeWellsNamesEvent && event.detail.context != CONTEXT){
  //     return;
  //   }
  //   let dataset = event.detail;
  //   let prevState = this.state.newWellsNames;
  //   prevState = this.processNewWellsNames(dataset.wellInfosContents,prevState);
  //   const datalist = this.state.dataList.map((data)=>{
  //     let new_name = data[2];
  //     if( prevState[data[0]] ){
  //       new_name = prevState[data[0]][data[1]]
  //     }
  //     return [data[0], data[1], new_name]
  //   })
  //   this.setState({newWellsNames:prevState,dataList:datalist});
  // }

  // processNewWellsNames (dataset:any, prevState:any){
  //   dataset.forEach((row:string[],rowIdx:number)=>{
  //     if(!Object.keys(prevState).includes(row[0])){
  //       prevState[row[0]] = {};
  //     }
  //     if(!prevState[row[0]][row[1]]){
  //       prevState[row[0]][row[1]] = row[2];
  //     }
  //   })
  //   return prevState;
  // }

  fixWellsNames =(event:any) => {
    if (event.type !== this.changeWellsNamesEvent && event.detail.context != CONTEXT){
      return;
    }
    let dataset = event.detail;
    let prevState = this.state.newWellsNames;
    prevState = this.processNewWellsNames(dataset.wellInfosContents);
    this.setState({newWellsNames:prevState});
  }

  processNewWellsNames (dataset:any){
    let newWellsNames: any[] = []
    dataset.forEach((row:string[],rowIdx:number)=>{
      // if(!Object.keys(newWellsNames).includes(row[0])){
      //   newWellsNames[row[0]] = {};
      // }
      // if(!newWellsNames[row[0]][row[1]]){
      //   newWellsNames[row[0]]["oldName"] = row[1];
      //   newWellsNames[row[0]]["newName"] = row[2];
      // }
      newWellsNames.push({"oldName": row[1], "newName": row[2]});
    })
    return newWellsNames;
  }


  setFiles = (event:any) => {
    if (event.type !== this.loadFileComponentEvent && event.detail.context != CONTEXT){
      return;
    }

    const dataset = event.detail;
    let wells_infos = dataset.fileList.map((file:any,fileIdx:number)=>{
      return [file.name, file.name.split(".")[0], file.name.split(".")[0]]
    })
    updateTable(wells_infos,CONTEXT);    
    this.setState({fileList:dataset.fileList, dataList:wells_infos},()=>{});
  }
 


 /**
   * Upload data
   */
 upload = () =>{

  if(this.state.fileList.length == 0){
    ToastHelper.warning("There is no information to load, please add files in 'Load File' tab and try again.");
    return;
  }

  // const wells_names = this.state.dataList.map((row,rowIdx)=>{
  //   return row[1];
  // });

  // let new_well_names:any[] = this.state.dataList.map((data)=>{
  //   return {oldName:data[1],newName:data[2]}
  // });

  // Object.keys(this.state.newWellsNames).forEach((filename:string,idx:number)=>{
  //   Object.keys(this.state.newWellsNames[filename]).forEach((old_wellName:string,wellIdx)=>{
  //     new_well_names.push({oldName:old_wellName,newName:this.state.newWellsNames[filename][old_wellName]});
  //   });
  // });

  const new_well_names = this.processNewWellsNames(this.state.dataList);

  
  if(!this.state.uploading){
    this.setState({uploading:true},()=>{
      var files = this.state.fileList;
      var data = new FormData();
      var wellNames = [];

      for(var idx = 0; idx<files.length; idx++){
        data.append("file", files[idx]);
        wellNames.push(files[idx].name.split(".")[0])
      }
      data.append("data",JSON.stringify({newWellNames:new_well_names}))
      this.runUploadWellLogs(data);
    });
  }
 }

   runUploadWellLogs = (data:FormData) => {
    const uploading = uploadWellLogs(data);
    uploading.then((response:any) =>{      
      this.setState({uploading:false})
      if(response.response.errors.length > 0){
        var files_error = response.response.errors.join(", ");
        ToastHelper.error("Error when upload well las on files: " + files_error, ()=>{
          console.log(files_error);
        });
      }
      if (response.response.success.length > 0){
        var files_success = response.response.success.join(", ");
        ToastHelper.success("Upload well logs was successful! " + files_success);
      }
      refreshDataTab();
    }).catch((response:any)=>{
      ToastHelper.error("Error when upload well las","One or many file contains errors!");
      this.setState({uploading:false});
      refreshDataTab();
    })
  }

setTab = (tabName:string) => {
  this.setState({activeTab:tabName});
}

tabHeader = () =>{
  return (
    <div className="process-nav">{tabOrder.map((currentTab,idx)=>{
      return (
        <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
          {currentTab}
        </div>
      )
    })}
    </div>
  )
}

tabsView = () =>{
  let sectionStyle = {
    marginTop: '0px'
  };

  let navItemStyle = {
    backgroundColor: "white",
    color: StandartColors.DarkBlack,
    //padding: '0 15px 0 15px'
  };

  return (
    <section id="tabs" style={sectionStyle}>
      <div className="row mb-3">
        
        {this.tabHeader()}
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="tab-content" id="nav-tab-map-view-component" style={{backgroundColor:"white"}}>
            <div
              className={`tab-pane fade show ${this.state.activeTab === TabNames.uploadFile ? 'active' : ''}`}
              id="Map-View"
              role="tabpanel"
              style={navItemStyle}
            >
              <LoadFileComponent context={CONTEXT}/>
            </div>
            <div
              className={`tab-pane fade show ${this.state.activeTab === TabNames.selectData ? 'active' : ''}`}
              id="Map-View"
              role="tabpanel"
              style={navItemStyle}
            >
              <b>Nothing to show</b>             
            </div>
            <div
              className={`tab-pane fade show ${this.state.activeTab === TabNames.correctWellInfo ? 'active' : ''}`}
              id="Map-View"
              role="tabpanel"
              style={navItemStyle}
            >
              <div className='row'>
                <div className='col'>
                  <b>Correct information read in file:</b>
                </div>
                <div className='col-2 mb-3'>
                  <input type="button" className="btn btn-dark" value="Load" onClick={()=>{this.upload()}} />
                </div>
              </div>
              <FixWellInfosTable wellsData={this.state.dataList} context={CONTEXT} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )

}

  render() {  
    if(this.state.uploading){
      return (
      <>
      <h2>Uploading Well Logs</h2>
      <PulseLoader size={20} />
      </>
      )
    }
    else{
      return (
        <>
        {this.tabsView()}
        </>
      );
    }
    }
}

