import React, { Component } from 'react';
import SignUp from '../../components/Login/SignUp';

interface SignUpPagetate {
  
}

class SignUpPage extends Component<{}, SignUpPagetate> {
  constructor(props: any) {
    super(props);
  }  

  render() {

    return (
      <div style={{marginTop: '-30vh', marginLeft:'20vw',  marginRight:'20vw'}}>
        <SignUp />
      </div>      
    );
  }
}

export default SignUpPage;
