import React, { Component } from 'react';
import TreeViewWellComponent, {refreshWellsTree} from '../../TreeView/TreeViewWellComponent';
import CorrelationsTreeView, {refreshCorrelationTreeViewDataTab} from '../../correlationsTreeView/CorrelationsTreeView';
import MarkersTreeView, {refreshMarkersTreeViewDataTab} from '../../MarkersTreeView/MarkersTreeView';

import { updateConfig } from '../SettingsTabComponent/SettingsTabComponent';

interface DataTabComponentState {
  
}

export function refreshDataTab(){
  refreshWellsTree();
  refreshCorrelationTreeViewDataTab();  
  refreshMarkersTreeViewDataTab();
  updateConfig()
}

class DataTabComponent extends Component<{}, DataTabComponentState> {
  constructor(props: any) {
    super(props);
  }

  render() {

    return (
      <>
        <TreeViewWellComponent/>
        <CorrelationsTreeView />
        <MarkersTreeView/>
      </>
    );
  }
}

export default DataTabComponent;
