export default class WellLogInfoContainer {
    wellname: string;
    version: number;
    logs: string[];
  
    constructor(wellname: string, version: number, logs: string[]) {
      this.wellname = wellname;
      this.version = version;
      this.logs = logs;
    }
  }