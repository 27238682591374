//External packages
import React, { Component, useRef } from 'react';
import Slider from '@mui/material/Slider';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

// Internal Packages
import "./GeologicalSettingsCorrelationTab.css"
import { CorrelationTabEvents } from '../../../../../utils/enums/CorrelationsTab';
import { 
        wellMetadata, 
        getWellDeviationProperties, 
        getSeaLevel, 
        getCorrelations, 
        wellBoundaries
} from '../../../../../services/wellService';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

const theme = createTheme({
    palette: {
      primary: {
        light: '#eeeeee',
        main: '#9e9e9e',
        dark: '#424242',
      }
    },
  });

interface GeologicalSettingsCorrelationTabState {
    radiusValue: number; 
    correlationsNumber: number;     
    currentValueInput: number;
    enabledWellDepth: string[];
    correlationOffset:number;
    correlationResolution:number;
    correlationDepth:string;
    timerToSend:any;
    tableRows: any;
    wellsInfo:any;
    wellMarkers:string[];
    wellsSeaLevel: any;
    seaLevelOptionSelected: boolean;    
    boundaries:any;
}

interface GeologicalSettingsCorrelationTabProps {
    correlationRadius:number;
    correlationOffset:number;
    correlationResolution:number;
    correlationDepth:string;
    wells:string[];
    tableRows: any;
}

const min = 1.000;
const max = 10000;

const doe_value = (value:number) =>{
    return Math.pow(min, 1 - value) * Math.pow(max, value);
}

const doe_value_inverse = (value:number) =>{
    return (Math.log(value) - Math.log(min)) / (Math.log(max) - Math.log(min));
}

const well_depth = [
    {key:"MD", show:"MD"},
    {key:"TVD", show:"TVD"},
    {key:"TVDSS", show:"TVDSS"}
];

function valuetext(value: number) {
    return `${parseFloat(doe_value(value).toString()).toFixed(2)} Km`;
}

const rangeMarks = [
    {
        value: 0,
        label: '1 Km',
    },
    {
        value: 0.25,
        label: `${doe_value(0.25).toFixed(2)} Km`,
    },
    {
        value: doe_value_inverse(100),
        label: `${doe_value(doe_value_inverse(100)).toFixed(2)} Km`,
    },
    {
        value: 0.75,
        label: `${doe_value(0.75).toFixed(2)} Km`,
    },
    {
        value: 1,
        label: '10000 Km',
    }
]


class GeologicalSettingsCorrelationTab extends Component<GeologicalSettingsCorrelationTabProps, GeologicalSettingsCorrelationTabState> {

    geologicalSettingsUpdateEvent: string = CorrelationTabEvents.geologicalSettingsUpdate;

    constructor(props:any){
        super(props);

        let tableRowsData = this.props.tableRows;
        if(tableRowsData.length==0){
            tableRowsData = [{name:"Correlation Start",marker:"", wells:{}}, {name:"Datum", marker:"", wells:{}}, {name:"Correlation Stop",marker:"", wells:{}}]
        }
        // this.setRadiusValue(e.target.value);
        // this.setCurrentValueInput(doe_value(e.target.value));
        this.state = {
            radiusValue: doe_value_inverse(this.props.correlationRadius),
            correlationsNumber: 0,
            currentValueInput:  this.props.correlationRadius,
            enabledWellDepth: ['MD',"TVD","TVDSS"],
            correlationDepth:this.props.correlationDepth,
            correlationResolution:this.props.correlationResolution,
            correlationOffset:this.props.correlationOffset,
            timerToSend:null,
            tableRows:tableRowsData,
            wellsInfo:{},
            // wellMarkers:["mk1","mk2"],
            wellMarkers:[],
            wellsSeaLevel: {},
            seaLevelOptionSelected: false,
            boundaries:{}
        }

        const sea_level = getSeaLevel();
        sea_level.then((sea_level)=>{
            this.setState({wellsSeaLevel:sea_level})
        })
        this.updateBoundariesTableDataset()
        // const well_metadata = wellMetadata();
        // well_metadata.then((metadata)=>{
        //     Object.keys(metadata).forEach((wellName:any,idx)=>{
        //         if(metadata[wellName].well_info){
        //             if(this.props.wells.includes(wellName) && metadata[wellName].well_info.STRT){
        //                 if(!tableRowsData[0].wells[wellName]){
        //                     tableRowsData[0].wells[wellName] = this.state.boundaries[wellName].start[this.state.correlationDepth];
        //                 }
        //                 if(!tableRowsData[tableRowsData.length - 1].wells[wellName] && metadata[wellName].well_info.STOP){
        //                     tableRowsData[tableRowsData.length - 1].wells[wellName] = this.state.boundaries[wellName].stop[this.state.correlationDepth];
        //                 }
        //                 for(let rowIdx = 1; rowIdx <= tableRowsData.length - 2; rowIdx++){
        //                     if(!tableRowsData[rowIdx].wells[wellName]){
        //                         tableRowsData[rowIdx].wells[wellName] = 0;
        //                     }
        //                 }
        //             }
        //         }
        //     });
        //     this.setState({tableRows:tableRowsData},() => this.calculateCorrelations());
            
        // });

        this.calculateCorrelations();
        this.setTimerToSendChanges();
        this.numberChange = this.numberChange.bind(this)
    }

    componentDidMount(): void {
        // 
    }

    updateBoundariesTableDataset = () =>{
        wellBoundaries().then((response)=>{
            this.setState({boundaries:response},()=>{this.updateBoundariesTable()})
        })
    }

    updateBoundariesTable = () =>{
        const boundariesData = this.state.boundaries;
        let tableRowsData = this.state.tableRows
        this.props.wells.forEach((wellName:any,idx)=>{
            if(boundariesData[wellName]){
                if(this.props.wells.includes(wellName) && this.state.boundaries[wellName].start[this.state.correlationDepth]){
                    if(!tableRowsData[0].wells[wellName]){
                        tableRowsData[0].wells[wellName] = this.state.boundaries[wellName].start[this.state.correlationDepth];
                    }
                    if(!tableRowsData[tableRowsData.length - 1].wells[wellName] && this.state.boundaries[wellName].stop[this.state.correlationDepth]){
                        tableRowsData[tableRowsData.length - 1].wells[wellName] = this.state.boundaries[wellName].stop[this.state.correlationDepth];
                    }
                    for(let rowIdx = 1; rowIdx <= tableRowsData.length - 2; rowIdx++){
                        if(!tableRowsData[rowIdx].wells[wellName]){
                            tableRowsData[rowIdx].wells[wellName] = 0;
                        }
                    }
                }
            }
        });
        this.setState({tableRows:tableRowsData})
    }

    setTimerToSendChanges = () => {
        if(this.state.timerToSend != null){
            clearTimeout(this.state.timerToSend);
        }
        var timer = setTimeout(this.sendConfs,1000);
        this.setState({timerToSend:timer});
    }

    sendConfs = () =>{
        const customEvent = new CustomEvent(this.geologicalSettingsUpdateEvent, { detail: {
            correlationRadius:parseFloat(this.state.currentValueInput.toString()),
            correlationDepth: this.state.correlationDepth,
            correlationResolution: this.state.correlationResolution,
            correlationOffset: this.state.correlationOffset,
            boundariesTable: this.state.tableRows,
        } 
        });
        document.dispatchEvent(customEvent);
    }

    doe_value = (value:number) =>{
        return Math.pow(min, 1 - value) * Math.pow(max, value);
    }
    
    doe_value_inverse = (value:number) =>{
        return (Math.log(value) - Math.log(min)) / (Math.log(max) - Math.log(min));
    }

    
    setCurrentValueInput = (value: number, callback?:any) =>{
        this.setState({currentValueInput: value}, ()=>{
            if(callback){
                callback();
            }
        });
        this.setTimerToSendChanges();
    }
    
    setRadiusValue = (value: number) =>{
        this.setState({radiusValue: value}, ()=> this.calculateCorrelations());
        this.setTimerToSendChanges();
    }

    setGeologicalDepth = (value:string) => {
        this.setState({correlationDepth:value},()=>{this.updateBoundariesTable()});
    }

    setCorrelationResolution = (value:number, callback:any) => {
        this.setState({correlationResolution:value},()=>{callback()});
        this.setTimerToSendChanges();
    }

    setCorrelationOffet = (value:number) => {
        this.setState({correlationOffset:value});
        this.setTimerToSendChanges();
    }

    dragInput = (e:any) => {
        // console.log("Drag number", e.target.value, doe_value(e.target.value));
        this.setRadiusValue(e.target.value);
        this.setCurrentValueInput(doe_value(e.target.value));
    };

    numberChange = (e:any,callback:any) => {
        // console.log(
        //     "Input number",
        //     e.target.value,
        //     doe_value_inverse(e.target.value)
        // );
        this.setCurrentValueInput(e.target.value, callback);
        this.setRadiusValue(doe_value_inverse(parseFloat(e.target.value)));
    };

    calculateCorrelations = () => {        
        // let wells: string[] = []
        // Object.keys(this.state.tableRows[0]["wells"]).forEach((wellname:string)=>{
        //     wells.push(wellname)            
        // })
        const data = { 
            "wellnames": this.props.wells, 
            "distance": parseFloat(this.state.currentValueInput.toString())
        };                
        const correlations = getCorrelations(data);
        correlations.then((correlations)=>{
            const numberCorrelations = correlations["number_pairs"];            
            this.setState({correlationsNumber:numberCorrelations})  
        })
              
    }


    setMarker = (markerName:string, rowIdx:number) =>{
        let rows = this.state.tableRows;
        rows[rowIdx].marker = markerName;
        this.setState({tableRows:rows})
        this.setTimerToSendChanges();
    }

    setData = (value:number, rowIdx:number, wellName:string) =>{
        let rows = this.state.tableRows;
        rows[rowIdx].wells[wellName] = value;
        this.setState({tableRows:rows})
        this.setTimerToSendChanges();
    }

    addRow = (rowIdx:number) => {
        let rows = this.state.tableRows;
        let rowsWells = Object.keys(rows[0].wells);
        let row = {name:"Marker Constraints", marker:"", wells:{}}
        // Comprehension in JS!
        row.wells = Object.fromEntries(rowsWells.map( well => [well,0]));
        rows.splice(rowIdx + 1, 0, row);
        this.setState({tableRows:rows});
        this.setTimerToSendChanges();
    }

    removeRow = (rowIdx:number) => {
        let rows = this.state.tableRows;
        rows.splice(rowIdx, 1);
        this.setState({tableRows:rows});
        this.setTimerToSendChanges();
    }

    wellSetHandler = (value:string) => {  
        this.setGeologicalDepth(value)
        if(this.state.seaLevelOptionSelected){
            this.changeSeaLevel("sea_level", 1, value);
        }        
        this.setTimerToSendChanges();      
    }

    selectMarkerHandler = (value:any, rowIdx: number)=>{        
        this.setMarker(value, rowIdx);
        this.changeSeaLevel(value, rowIdx);
        value == "sea_level" ? this.setState({seaLevelOptionSelected: true}) : this.setState({seaLevelOptionSelected: false});        
        this.setTimerToSendChanges();
    }

    changeSeaLevel = (value:any, rowIdx: number, correlationDepth:string = this.state.correlationDepth)=>{        
        let rows = this.state.tableRows;
        rows[rowIdx].marker = value;
        if(rowIdx == 1 && value == "sea_level"){
            Object.keys(rows[rowIdx].wells).forEach((well)=>{
                rows[rowIdx].wells[well] = this.state.wellsSeaLevel[well][correlationDepth];
            })        
        }else if(rowIdx == 1 && value != "sea_level"){
            Object.keys(rows[rowIdx].wells).forEach((well)=>{
                rows[rowIdx].wells[well] = 0;
            }) 
        }
        this.setState({tableRows: rows}, ()=>{this.setTimerToSendChanges()}) 
    }


    renderBoundariesRows = () =>{
        return(
            <>
                {
                    this.state.tableRows.map((row:any,rowIdx:any)=>{                       
                        return(
                            <tr>
                                <td>
                                    { (rowIdx != 0 && rowIdx != this.state.tableRows.length-1 && rowIdx != 1) && <FaMinusCircle style={{marginLeft:"-9%"}} onClick={()=>{this.removeRow(rowIdx)}} />}
                                    <span>{row.name}</span>
                                    { (rowIdx != 0 && rowIdx != this.state.tableRows.length-1) && <FaPlusCircle style={{marginBottom:"-25%"}} onClick={()=>{this.addRow(rowIdx)}} />}
                                </td>
                                <td>
                                    <select onChange={(event)=>{this.selectMarkerHandler(event.target.value,rowIdx)}}>
                                        {(rowIdx == 0 || this.state.tableRows.length -1 == rowIdx) && <option value="">Default</option>}
                                        {(rowIdx != 0 && this.state.tableRows.length -1 != rowIdx) && <option value="" selected>Select</option>}      
                                        {(rowIdx == 1) && <option value="sea_level">Sea Level</option>}
                                        {this.state.wellMarkers.map((marker,markerIdx)=>{
                                            return <option value={marker} selected={row.marker == marker}>{marker}</option>
                                        })}
                                    </select>
                                </td>
                                {this.props.wells.map((wellName,idx)=>{
                                    return (
                                        <td 
                                            contentEditable={true} 
                                            onInput={(event:any)=>{ this.setData(parseFloat(event.target.innerText),rowIdx,wellName) }}
                                            >
                                            {row.wells[wellName]}
                                        </td>
                                    )
                                })}
                            </tr>
                        )                        
                    })
                }
            </>
        )
    }

    renderBoundariesTable = () =>{
        if(this.props.wells.length > 0){
            return(
                <>
                    <table className='table-bordered boundariesTable'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Select a marker</th>
                                {this.props.wells.map((wellName,idx)=>{
                                    return <th>{wellName}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderBoundariesRows()} 
                        </tbody>
                    </table>
                </>
            );
        }
        return <>Select at least one well in <b>Wells tab</b> to display boundaries table</>
    }
    
    render(): React.ReactNode {
        return(
            <>
                <div className="row mb-5">
                    <div className="col-7" style={{display:"grid"}}>
                        <label style={{width:"100%", display:"grid"}}>Radius of Correlation:
                            <div className='radiusStyle' style={{justifyContent:'space-between'}}>
                                <Slider
                                    aria-label="Distance"
                                    defaultValue={this.state.radiusValue}
                                    valueLabelDisplay="auto"
                                    step={0.00001}
                                    min={0}
                                    max={1}
                                    value={this.state.radiusValue}
                                    valueLabelFormat={valuetext}
                                    marks={rangeMarks}
                                    onChange={(event) => {
                                        this.dragInput(event);
                                    }}
                                    sx={{
                                        color:"grey",
                                        width:"70%",
                                        marginLeft:"10px",
                                        marginRight:"20px",
                                    }}
                                />
                                <div className='radiusDiv'>
                                    <input
                                        type="text"
                                        value={parseFloat(this.state.currentValueInput.toString()).toFixed(2).toString()}
                                        onChange={(event)=>{
                                            event.persist()
                                            const caretStart = event.target.selectionStart;
                                            const caretEnd = event.target.selectionEnd;
                                            this.numberChange(event, ()=>{event.target.setSelectionRange(caretStart, caretEnd);});
                                            
                                        }}
                                        max={max}
                                        step={0.01}
                                        min={min}
                                    />
                                    Km
                                </div>
                            </div>
                        </label>
                        <div className="row mt-3 pt-3">
                            <div className="col mt-2">
                                <label>Number of correlations:&nbsp;
                                    <span style={{padding: "0px 5px"}}>{this.state.correlationsNumber}</span>                                                                      
                                </label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-5">
                        <div className="row">
                            <div className="col"> 

                            <label>Correlation in:&nbsp;</label>
                                    
                            <select id="wellSet" style={{width:"30%"}} className='py-1 px-2 ml-2' onChange={(event)=>{this.wellSetHandler(event.target.value)}}>
                                {well_depth.map((depth, idx)=>{
                                    return <option value={depth.key} selected={idx==0} disabled={!this.state.enabledWellDepth.includes(depth.key)}>{depth.show}</option>
                                })}
                                
                            </select>                        
                            </div>

                        </div>
                        <div className="row mt-3"> 
                            <div className="col"> 
                                <label className='mb-2' style={{width:"100%"}}>Maximum correlation offset:&nbsp;
                                    <input
                                        className='py-1 px-2'
                                        type="text"
                                        style={{width:"30%"}}
                                        placeholder='Add the measure'
                                        value={this.state.correlationOffset.toFixed(0).toString()}
                                        onChange={(event)=>{this.setCorrelationOffet(parseFloat(event.target.value))}}
                                    /> <strong>m</strong> 

                                </label>
                            </div>
                        </div> 
                        <div className="row mt-3">
                            <div className="col">
                                <label>Correlation resolution:&nbsp;
                                    <input
                                        className='py-1 px-2'
                                        type="text"
                                        style={{width:"30%"}}
                                        placeholder='Add the measure'
                                        value={this.state.correlationResolution.toFixed(4).toString()}
                                        onChange={(event)=>{
                                            event.persist()
                                            const caretStart = event.target.selectionStart;
                                            const caretEnd = event.target.selectionEnd;
                                            this.setCorrelationResolution(parseFloat(event.target.value), ()=>{event.target.setSelectionRange(caretStart, caretEnd);})}
                                        }
                                    /> <strong>m</strong>   

                                </label>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="row mt-3">
                    <div className='boundariesTableDiv'>
                        {this.renderBoundariesTable()}
                    </div>
                </div>
            </>
        )
    }
}

export default GeologicalSettingsCorrelationTab;