import axios from "axios";
import { getToken } from "./TokenHelper";

const pathname = window.location.pathname;

axios.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.NautilusAuthorization = `NautilusToken ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if(error.response){
      if (error.response.status === 401 && !originalRequest._retry) {       
        if (pathname !== "/auth/sign-in") {
          window.location.replace("/auth/sign-in");
        } 
      }    
    } else {
      throw error;
    }

    return error;
  }
);