// Most Common
export const Semicolon = { display: 'Semicolon (;)', value: ';' };
export const Comma = { display: 'Comma (,)', value: ',' };
export const DoubleQuotation = { display: 'Double quotation mark (")', value: '"' };
export const FourSpaces = { display: 'Four spaces', value: '    ' };
export const OneSpace = { display: 'One space', value: ' ' };
export const Tabulation = { display: 'Tabulation (Tab)', value: '\t' };
export const Hash = { display: 'Pound/Hash symbol (#)', value: '#' };
export const ThreeSpaces = { display: 'Three spaces', value: '   ' };

// Less Common
export const Ampersand = { display: 'Ampersand (&)', value: '&' };
export const At = { display: 'At symbol (@)', value: '@' };
export const Asterisk = { display: 'Asterisk (*)', value: '*' };
export const Backslash = { display: 'Backslash (\\)', value: '\\' };
export const Caret = { display: 'Caret (^)', value: '^' };
export const Colon = { display: 'Caret (^)', value: '^' };
export const Dollar = { display: 'Dollar sign ($)', value: '$' };
export const Equals = { display: 'Equals sign (=)', value: '=' };
export const Exclamation = { display: 'Exclamation mark (!)', value: '!' };
export const GraveAccent = { display: 'Grave accent/backtick (`)', value: '`' };
export const Hyphen = { display: 'Hyphen (-)', value: '-' };
export const Period = { display: 'Period (.)', value: '.' };
export const Percent = { display: 'Percent sign (%)', value: '%' };
export const Pipe = { display: 'Pipe (|)', value: '|' };
export const Plus = { display: 'Plus sign (+)', value: '+' };
export const Question = { display: 'Question mark (?)', value: '?' };
export const SingleQuotation = { display: 'Single quotation mark (\')', value: '\'' };
export const Slash = { display: 'Slash (/)', value: '/' };
export const Space = { display: 'Space ( )', value: ' ' };
export const Tilde = { display: 'Tilde (~)', value: '~' };
export const TwoSpaces = { display: 'Two spaces', value: '  ' };
export const Underscore = { display: 'Underscore (_)', value: '_' };

// Distance Units
export const Meter = { display: 'Meter (m)', value: 'm'};
export const Foot = { display: 'Foot (ft)', value: 'ft' };
export const Kilometer = { display: 'Kilometer (km)', value: 'km' };
export const Mile = { display: 'Mile (mi)', value: 'mi' };
export const Yard = { display: 'Yard (yd)', value: 'yd' };

// Depts types
export const MD = { display: 'Measure Depth', value: 'MD'};
export const TVD = { display: 'True Vertical Depth', value: 'TVD'};
export const TVDSS = { display: 'True Vertical Depth Sub Sea', value: 'TVDSS'};

// No Values
export const m7777 = { display: '-7777.0', value: '-7777.0'};
export const m9999 = { display: '-9999.0', value: '-9999.0'};
export const m9999_99 = { display: '-9999.99', value: '-9999.99'};

export interface ParserParam{
    display:string,
    value:string
}

export default class ParserParameters{
    delimiters: ParserParam[] = [
        Semicolon,
        Comma,
        DoubleQuotation,
        FourSpaces,
        OneSpace,
        ThreeSpaces,
        Tabulation,
        Ampersand,
        At,
        Asterisk,
        Backslash,
        Caret,
        Colon,
        Dollar,
        Equals,
        Exclamation,
        GraveAccent,
        Hyphen,
        Period,
        Percent,
        Pipe,
        Plus,
        Hash,
        Question,
        SingleQuotation,
        Slash,
        Space,
        Tilde,
        TwoSpaces,
        Underscore,
    ]

    qualifiers: ParserParam[] = [
        DoubleQuotation,
        SingleQuotation,
        Semicolon,
        Comma,
        FourSpaces,
        OneSpace,
        Tabulation,
        Ampersand,
        At,
        Asterisk,
        Backslash,
        Caret,
        Colon,
        Dollar,
        Equals,
        Exclamation,
        GraveAccent,
        Hyphen,
        Period,
        Percent,
        Pipe,
        Plus,
        Hash,
        Question,
        Slash,
        Space,
        Tilde,
        TwoSpaces,
        Underscore,
    ]

    comments: ParserParam[] = [
        Hash,
        Semicolon,
        Comma,
        Ampersand,
        At,
        Asterisk,
        Backslash,
        Caret,
        Colon,
        Dollar,
        Equals,
        Exclamation,
        GraveAccent,
        Hyphen,
        Period,
        Percent,
        Pipe,
        Plus,
        Question,
        Slash,
        Tilde,
        Underscore,
    ]

    DistanceUnit: ParserParam[] = [
        Meter,
        Foot,
        Kilometer,
        Mile,
        Yard,
    ]

    DeptType: ParserParam[] = [
        MD,
        TVD,
        TVDSS,
    ]

    NoValues: ParserParam[] = [
        m7777,
        m9999,
        m9999_99,
    ]
}