import React, { Component } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoMdRefreshCircle } from "react-icons/io";
import { EventNames } from "../../utils/enums/EventNames";

interface headerParameters{
    name:string,
    editable?:boolean
}

interface fixWellInfoProps{
    wellsData: Array<string[]>;
    aditionalWellHeader?: headerParameters[];
    context:string;
}

interface fixWellInfoState{
    wellHeader: headerParameters[];
    wellsData: Array<string[]>;
    menuEditable: boolean;
    menuAnchor: any;
    prevTerm:string,
    newTerm:string
}

const SETVALUE = "setwellsinfos"

/**
 * Dispatch table update.
 * @param values new values to display.
 * @param context component context.
 */
export function updateTable(values:any[], context:string){
    const customEvent = new CustomEvent(SETVALUE, { detail: {
        values:values,
        context:context
    } 
    });
    document.dispatchEvent(customEvent);
}

export default class FixWellInfosTable extends Component<fixWellInfoProps, fixWellInfoState> {

    changeEvent = EventNames.updatedWellNamesEvent;

    constructor(props: any) {
        super(props);
        let header = [{name:"File Name"}, {name:"Well Name in File"}, {name:"Well Name In Nautilus", editable:true}];
        if(this.props.aditionalWellHeader){
            header = header.concat(this.props.aditionalWellHeader);
        }
        this.state = {
            wellHeader: header,
            wellsData: this.props.wellsData,
            menuEditable:false,
            menuAnchor:null,
            prevTerm:"",
            newTerm:""
        }
    }

    componentDidMount() {  
        document.addEventListener(SETVALUE, this.updateTable);
    }
    
    componentWillUnmount() {
        document.removeEventListener(SETVALUE, this.updateTable);
    }

    updateTable = (event:any) =>{
        if (event.type !== SETVALUE && event.detail.context != this.props.context){
            return;
        }
        let dataset = event.detail;
        this.setState({wellsData:dataset.values});
    }

    /**
     * Update single registry.
     * @param row Row index.
     * @param col Column index.
     * @param value New value to set.
     */
    updateRegistry(row:number,col:number,value:string){        
        let cells = this.state.wellsData;
        cells[row][col] = value;
        this.setState({wellsData:cells});
        this.sendUpdates();
    }

    /**
     * Update all registry in column.
     * @param col Column index.
     */
    updateColumnsRegistry(col:number){
        let cells = this.state.wellsData;
        cells = cells.map((row,rowIdx)=>{
            let cell = row[col];
            row[col] = cell.replaceAll(this.state.prevTerm, this.state.newTerm)
            return row;
        })
        this.setState({wellsData:cells});
        this.sendUpdates();
    }

    /**
     * Dispatch update event.
     */
    sendUpdates (){
        const customEvent = new CustomEvent(this.changeEvent, { detail: {wellInfosContents:this.state.wellsData, context:this.props.context}});
        document.dispatchEvent(customEvent);
    }

    render(): React.ReactNode {
        return(
            <div className="table-responsive">
             <table className="table">
                <thead>
                    <tr>
                        {this.state.wellHeader.map((field,idx)=>{
                            if(field.editable){
                                return(<th key={idx} scope="col">{field.name} <IoMdRefreshCircle onClick={(event)=>{this.setState({menuEditable:!this.state.menuEditable,menuAnchor:event.currentTarget})}}/>
                                    <Menu open={this.state.menuEditable} anchorEl={this.state.menuAnchor} onClose={()=>{this.setState({menuEditable:false})}}>
                                        <MenuItem>
                                        <div>
                                            <div className="row"><div className="col"><b>Name Replacement</b></div></div>
                                            <div className="row mt-1">
                                                <div className="col-4">Name</div><div className="col-8"><input type="text" style={{maxWidth:"100%"}} onChange={(event)=>{this.setState({prevTerm:event.target.value})}} /></div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-4">Replacer</div><div className="col-8"><input type="text" style={{maxWidth:"100%"}} onChange={(event)=>{this.setState({newTerm:event.target.value})}}  /></div>
                                            </div>
                                            <div className="row mt-1"><div className="col-4 offset-8"><input type="button" className="btn btn-dark" value="Replace" onClick={()=>{this.updateColumnsRegistry(idx)}} /></div></div>
                                        </div>
                                        </MenuItem>
                                    </Menu>
                                </th>)
                            }
                            else{
                                return <th scope="col">{field.name}</th>
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.wellsData.map((rowData, rowIdx)=>{                            
                            return (
                                <tr>
                                    {
                                        rowData.map((colData:string,colIdx:number)=>{
                                            return <td
                                                contentEditable={this.state.wellHeader[colIdx]?.editable} 
                                                suppressContentEditableWarning={true}
                                                // onInput={(event:any)=>{this.updateRegistry(rowIdx,colIdx,event.target.innerText)}}
                                                onBlur={(event:any)=>{this.updateRegistry(rowIdx,colIdx,event.target.innerText)}}
                                            >{colData}</td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
             </table>
            </div>
        )
    }
}