/* eslint-disable */
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ToastHelper from "../../utils/helpers/ToastHelper";
import LabelWell from './LabelWell';
import { deleteWells, deleteVersion, deleteLog } from "../../services/wellService";
import { EventNames } from '../../utils/enums/EventNames';
import StringHelper from '../../utils/helpers/StringHelper';

export default class WellNode {
  static confirmDialog = (message: any, confirm: any, confimArgs: any) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui kog-style-font-header'>
            <h1>Are you sure?</h1>
            <p>{message}</p>          
            <button className='kog-btn-yes'
              onClick={() => {
                confirm(confimArgs);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
            <button className='kog-btn-no' onClick={onClose}>No</button>
          </div>
        );
      }
    });
  }  
  
  static getVisibleLogsCount = (version: any) =>{
    var count=0;
    for(var i=0;i<version.logs.length;i++){
      var log = version.logs[i];
      if(log.visible){
        count++;
      }
    }
    return count;
  }
  
  static getVisibleVersionsCount = (well: any) =>{
    var count=0;
    for(var i=0;i<well.versions.length;i++){
      var version = well.versions[i];
      if(version.visible){
        count++;
      }
    }
    return count;
  }
  
  static deleteWellNode = (well: any) =>{ 
    
   var message = 'This action will delete all versions for the ' + well.label + ' well';
   this.confirmDialog(message, ()=>{this.deleteWellService(well);}, null);
  }
  
  static deleteVersionNode = (version: any, well: any) =>{
    try{    
         
      var message = '';
  
      if(this.getVisibleVersionsCount(well) === 1){
        message = 'This version is the only one in the '+ well.label +' well, so the well will removed.'       
        this.confirmDialog(message, ()=>{this.deleteWellService(well);}, null);
      } else{
        message = 'This action will delete all logs from the version Resolution ' + version.label + '.';
        this.confirmDialog(message, ()=>{this.deleteVersionService(null, version, well);}, null);
      }
      
    }catch(error){
      console.log(error);
    }
    
  }
  
  static deletePropertyNode = (log: any, version: any, well: any) =>{
    try{       
      var message = 'The '+ log.label +' log will be removed.';
      this.confirmDialog(message, ()=>{this.deleteLogService(log, version, well);}, null);          
    }catch(error){
      console.log(error);
    }
  }
  
  static split = (str: any) => {
    return StringHelper.parseIdStringToObject(str)
  }
  
  static deleteLogService = (log: any, version: any, well: any) =>{
    var result = this.split(log.id)  
    deleteLog(result, ()=>{
      this.success(`Log ${log.label} deleted with success!`);
      const customEvent = new CustomEvent(EventNames.hideNodesEvent, { detail: {log: log, version: version, well:well} });
      document.dispatchEvent(customEvent);
    }, ()=>{this.error(`Error when deleting Log ${log.label}`)});
  }
  
  static deleteVersionService = (log: any, version: any, well: any) =>{
    var result = this.split(version.id)
    deleteVersion(result, ()=>{
      this.success(`Resolution ${version.label} deleted with success!`)
      const customEvent = new CustomEvent(EventNames.hideNodesEvent, { detail: {log: log, version: version, well:well} });
      document.dispatchEvent(customEvent);
    }, ()=>{this.error(`Error when deleting Resolution ${version.label}`)});
  }
  
  static deleteWellService = (well: any) =>{  
    var result = this.split(well.id)
    deleteWells(result, ()=>{
      this.success(`Well ${well.label} deleted with success!`);
      const customEvent = new CustomEvent(EventNames.hideNodesEvent, { detail: {log: null, version: null, well:well} });
      document.dispatchEvent(customEvent);
    }, ()=>{
      this.error(`Error when deleting Well ${well.label}`)
    });
  }
  
  static success = (message: any) =>{
    ToastHelper.success(message)
  }
  
  static error = (message: any) =>{
    ToastHelper.error(message,()=>{console.log(message)})
  }
  
  static getLogNodes = (well: any) => {
    return well.logs.map((log: any) => ({
        value: log,
        label: <LabelWell callback={() => this.deletePropertyNode(log, log, log)}>{log.label}</LabelWell>,
      })
    );
  };  

  static getNodes(wells: any) {
    return wells.map((well: any) => ({
      value: well,
      label: <LabelWell callback={() => this.deleteWellNode(well)}>{well.label}</LabelWell>,
      children: well.logs.length > 0 ? this.getLogNodes(well) : null
    }));
  }
} 
