import React, { Component } from "react";

import Tracks from "./Tracks";
import BarTracks from "./BarTracks";

interface KogChartsState{
    data:any;
    plotConfig?:any;
}

interface KogChartsProps{
    data:any;
    plotConfig?:any;
    trackLeftClickOptions?:any[];
}

export default class KogCharts extends Component<KogChartsProps, KogChartsState> {
    constructor(props: any) {
        super(props);

        this.state = {data:this.props.data, plotConfig:this.props.plotConfig}
    }

    render(): React.ReactNode {
        return(
            <>
                <Tracks data={this.props.data} plotConfig={this.props.plotConfig} trackLeftClickOptions={this.props.trackLeftClickOptions} />
                {/* <BarTracks data={this.props.data}  /> */}
            </>
        )
    }
}