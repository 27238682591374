import React, { Component, ReactNode } from 'react';
import CheckboxTreeComponent from './CheckboxTreeComponent';
import CollapsibleTreeView from './CollapsibleTreeView';
import { EventNames } from '../../utils/enums/EventNames';
import { refreshTreeComponent } from './CheckboxTreeComponent';

interface SingletonCheckboxTreeComponentProps {
  nodes: any;
}

interface SingletonCheckboxTreeComponentState {
  wellNodes: any;
}

export function refreshWellsNodes(wellNodes:any){
  const customEvent = new CustomEvent(EventNames.refreshWellNodesEvent, { detail: { wellNodes:wellNodes } });
  document.dispatchEvent(customEvent);
}

export default class SingletonCheckboxTreeComponent extends Component<SingletonCheckboxTreeComponentProps, SingletonCheckboxTreeComponentState> {
  private static wellNodes: any | null = null;
  public static nodes: any | null = [];
  public static removedIds: any | null = [];
  public static stratigraphicColumnRefresh: boolean = true;

  public static setWellNodes(props: any): void {
    if (!SingletonCheckboxTreeComponent.wellNodes) {
      SingletonCheckboxTreeComponent.wellNodes = props.nodes;
    }
  }

  public static setWellNodesForced(wellNodes: any): void {    
    SingletonCheckboxTreeComponent.wellNodes = wellNodes;   
  } 

  public static getWellNodes(): void {    
    return SingletonCheckboxTreeComponent.wellNodes;    
  }  

  constructor(props:any){    
    super(props);
    SingletonCheckboxTreeComponent.setWellNodes(props); 
    this.state = {
      wellNodes: SingletonCheckboxTreeComponent.getWellNodes()
    };  
  }

  componentDidMount() {  
    document.addEventListener(EventNames.checkedTreeViewEvent, this.handleCheckedTreeViewEvent as EventListener);
    document.addEventListener(EventNames.uploadMarkerEvent, this.handleUploadMarkersEvent as EventListener);
    document.addEventListener(EventNames.refreshWellNodesEvent, this.refreshWellNodes as EventListener);
  }

  componentWillUnmount() {
    document.removeEventListener(EventNames.checkedTreeViewEvent, this.handleCheckedTreeViewEvent as EventListener);
    document.removeEventListener(EventNames.uploadMarkerEvent, this.handleUploadMarkersEvent as EventListener);
    document.removeEventListener(EventNames.refreshWellNodesEvent, this.refreshWellNodes as EventListener);
  }

  refreshWellNodes = (event:any) =>{
    if (event.type !== EventNames.refreshWellNodesEvent){
      return;
    }

    const wellNodes = event.detail.wellNodes

    SingletonCheckboxTreeComponent.setWellNodes(wellNodes);
    this.setState({wellNodes:SingletonCheckboxTreeComponent.getWellNodes()}, ()=>{
      refreshTreeComponent(wellNodes)      
    });    
  }

  handleCheckedTreeViewEvent = (event: CustomEvent) => {
    if (event.type !== EventNames.checkedTreeViewEvent){
      return;
    }
    var nodesAsStringArray = event.detail.checkedElementAStringArray;
    var checkedItemsArray = event.detail.checkedItemsArray
    SingletonCheckboxTreeComponent.nodes = nodesAsStringArray;
  };

  handleUploadMarkersEvent = (event: CustomEvent) => {
    if (event.type !== EventNames.uploadMarkerEvent){
      return;
    }

    SingletonCheckboxTreeComponent.stratigraphicColumnRefresh = true;    
  };

  getChild() {
    return (
      <CheckboxTreeComponent
          nodes={this.state.wellNodes}
          hasSearch={false}
          expandNodesToLevel={1}
        />
    );
  }
  
  render(): ReactNode {
    // Access the wellNodes using the class name
    return (
      <CollapsibleTreeView title='Well' childrenComponent={this.getChild()} />
    );
  }
 
}