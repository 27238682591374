export default class CurveMetatdataInfo {
    name: string;
    unit: string;
    description: string;

    constructor(curveInfo: [string, string, string]) {
        this.name = curveInfo[0];
        this.unit = curveInfo[1];
        this.description = curveInfo[2];
    }
}