import CurveMetatdataInfo from "./CurveMetatdataInfo";

export default class WellVersionMetatdata {
    curveMetatdataInfoList: CurveMetatdataInfo[];
    id: number;
    name: string;

    constructor(curve: any) {
        this.curveMetatdataInfoList = [];
       
        var keys = Object.keys(curve.curves_info)
        for(var j=0;j<keys.length;j++){       
            var curveMetatdataInfo = new CurveMetatdataInfo(curve.curves_info[keys[j]]);         
            this.curveMetatdataInfoList.push(curveMetatdataInfo);
        }
        
        this.id = curve.id;
        this.name = curve.name;
    }
}