import React, { Component } from "react";
import ViewTabsComponent from '../../components/Tabs/ViewTabsComponent';
import ConfigTabsComponent from '../../components/Tabs/ConfigTabsComponent';
import { StandartColors } from "../../utils/enums/StandartColors";
import { EventNames } from "../../utils/enums/EventNames";
import "../../assets/main.css"
import { IoIosArrowForward } from "react-icons/io";


interface HomePageState {
  height: string;
  sideBarIsOpen: boolean;
}

class HomePage extends Component<{}, HomePageState> {

  resizeEventName: string = EventNames.resizeEvent;
  
  constructor(props: any){
    super(props)
    this.state = {
      height: '100vh',
      sideBarIsOpen: true
    };
  }

  resizeEandleEvent = (event: any) => {
    if (event.type !== this.resizeEventName){
      return;
    }

    if(!event.detail.triggerEvent){
      return;
    }

    var rowsCount = event.detail.rows;  
    var numTables = event.detail.numFilesSelected;

    var mapViewElementHeight = (rowsCount * 40.8 + 45.2 + 38.4 + 21.6*2 + 24 + 100) * numTables +170;
    
    const height = `${mapViewElementHeight}px`;
    this.setState({
      height: height,
    });
    
  };

  componentDidMount() {  
    document.addEventListener(this.resizeEventName, this.resizeEandleEvent);
  }

  componentWillUnmount() {
    document.removeEventListener(this.resizeEventName, this.resizeEandleEvent);
  }

  render() {
    const sidebarWidth = 300;
   
    return (         
      <>
        <div className="container-fluid">
          <div className="row" style={{ minHeight: '100vh', height: this.state.height }}>
            <div className={this.state.sideBarIsOpen? "sidebar-col sidebar-open" : "sidebar-col"} style={this.state.sideBarIsOpen? {width:`${sidebarWidth}px`, marginLeft: "0"} : {width:`${sidebarWidth}px`, marginLeft: `-${sidebarWidth}px`}}> 
              <ConfigTabsComponent />
              <button onClick={()=>this.setState({sideBarIsOpen: !this.state.sideBarIsOpen })} className={this.state.sideBarIsOpen? "sidebar-toggle is-open" : "sidebar-toggle"}>
                <IoIosArrowForward />
              </button>
            </div>            
            <div className="main-col" style={this.state.sideBarIsOpen? {width:`calc(100% - ${sidebarWidth}px)`} : {width:"100%"}}>
              <ViewTabsComponent />              
            </div>
          </div>
        </div>  
      </> 
    );
  }
  
}

export default HomePage;
