/* eslint-disable */
import React, { Component} from 'react'

interface LabelWellProps {
    callback: () => void;
    text: string;
    childCallbackArgs: any;
  }
  
  export class ClickableLabel extends Component<LabelWellProps> {
    
    callback: (childCallbackArgs: any) => void;    
    childCallbackArgs: any;
    text: string;

    constructor(props: any) {
       super(props);
       this.callback = props.callback;
       this.text = props.text;
       this.childCallbackArgs =  props.childCallbackArgs;
    }

    onClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        if(event){
            event.preventDefault();
        }        
        if(this.callback){
            this.callback(this.childCallbackArgs);
        }            
      };
  
    render() {
        return (
        <div style={{ display: 'inline' }}>
            <span
                style={{ cursor: 'pointer', marginLeft: '-7px', position: 'relative', top: '-1px' }}
                onClick={this.onClick}
            >
            {this.props.text}
            </span>
        </div>
        );
    }
  }