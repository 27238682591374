import React, { Component, ReactNode } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./FileUploaderComponent.css";
import { EventNames } from "../../utils/enums/EventNames";
import { upload, exists, updateWellHeader, wellMetadata } from "../../services/wellService";
import ToastHelper from "../../utils/helpers/ToastHelper";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { ActionsTabTypes } from "../../utils/enums/ActionsTabTypes";


const fileTypes = [".*"];

interface FileUploaderState {
  fileList: any | any[] | null;
  csvParserStateDict: any ;
}

interface FileUploaderProps {
  // children: any;
  fileList: any | any[] | null;
  context: string;
}

export default class FileUploaderComponent extends Component<FileUploaderProps, FileUploaderState> {  
  updateListEventName: string = EventNames.CsvUploaderComponentUpdateListEvent;     
  removeAllFilesEvent: string = EventNames.removeAllFilesEvent;

  wellnameDict: any;

  constructor(props: any) {
    super(props);
    this.state = {
      fileList: props.fileList,
      csvParserStateDict: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {      
    document.addEventListener(this.removeAllFilesEvent, this.removeAllFiles);

  }

  componentWillUnmount() {      
    document.removeEventListener(this.removeAllFilesEvent, this.removeAllFiles);
  }

  removeAllFiles(){
    this.setState({fileList:[]});
  }  

  handleChange(newFiles: any) {
    const filesToAdd = Array.isArray(newFiles) ? newFiles : [newFiles];
    const updatedFileList = [];

    if(this.state.fileList){
      for (var file of this.state.fileList) {
        updatedFileList.push(file);
      }  
      for (var file of filesToAdd[0]) {
        updatedFileList.push(file);
      }
    } else {
      for (var file of filesToAdd[0]) {
        updatedFileList.push(file);
      }
    }

    if(updatedFileList && updatedFileList.length > 0){
      this.setState({ fileList: updatedFileList });
      var fileInput: any = document.querySelector('input[type="file"]');
      if(fileInput){
        fileInput.files = new DataTransfer().files;
      }      
    }
    
    const customEvent = new CustomEvent(this.updateListEventName, { detail: {files: updatedFileList, context:this.props.context}  });
    document.dispatchEvent(customEvent);
  }  

  render() {
    var childrenWithProps: React.ReactElement<FileUploaderState>[] = [];

    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'inline-block', marginRight: '15px' }}>
            <FileUploader
              multiple={true}
              handleChange={this.handleChange}
              name="file"
              fileOrFiles={this.state.fileList}
            />
          </div>
        </div>        
      </div>
    );
  }
}
